import { axiosBase } from "../constants/axiosBase"

type GetIntegrationBalance = {
	seller_id?: number
	blogger_id?:number
}

export const getIntegrationBalance = async (data:GetIntegrationBalance) => {
	const response = await axiosBase.get("/balance/", {
		params: data
	})
	return response.data
}
