import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Link } from "react-router-dom"
import { Form } from "../../../component/Form"
import { Loader } from "../../../component/Loader"
import { host, MyTask } from "../../../classes"
import { Navigation } from "../../../component/Navigation"
import { PopupChat } from "../../../component/PopupChat"
import { PopupImage } from "../../../component/PopupImage"

import ym from "react-yandex-metrika"
import ReactGA from "react-ga"

export const BlogerAccountPage = (props: any) => {
	ReactGA.initialize("G-2HCSN416F3")

	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [, setLoadForm] = useState(true)
	const [showS3, setShowS3] = useState(false)
	const [applicationIndex, setApplicationIndex] = useState(0)

	const [showPopUpImage, setShowPopUpImage] = useState(false)
	const [showPopUpURL, setPopUpURL] = useState("")

	const [showChat, setShowChat] = useState(false)
	const [applicationID, setApplicationID] = useState(0)

	const [popupType, setPopupType] = useState("")
	const [myTasks] = useState(new MyTask())

	const statusList = [
		"in_process", // 1. Заявка принята
		"tz_conf", // 2. ТЗ принято
		"w8-paydata", // 3. Ожидание реквизитов
		"payment_w8", // 4. Ожидание оплаты
		"payment_completed", // 5. Оплата получена
		"product_ordered", // 6. Товар заказан
		"product_received", // 7. Товар получен
		"post_posted", // 8. Пост размещен
		"bloger_done", // 9. Успешно завершено
		"w8_bloger_feedback", // 10. Ожидание отзыва от блогера
	]

	useEffect(() => {
		myTasks
			.LoadData(1, auth.user.userToken)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	const clickIvent = (target: number) => {
		myTasks.targetPage = target
		setLoad(true)
		myTasks
			.LoadData(target, auth.user.userToken)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}
	const StatusUpdate = (index: number, clickIndex: number) => {
		myTasks
			.SetStatus(clickIndex, index, auth.user.userToken)
			.then(function (response) {
				setPopupType("Статус успешно обновлен")
			})
			.catch(function (error) {
				alert("error")
			})
	}
	const StepClick = (index: number, clickIndex: number, aplID: number) => {
		if (clickIndex === 1) {
			if (statusList.indexOf(myTasks.list[index].status) + 1 === clickIndex) {
				StatusUpdate(index, clickIndex)
			}
			window.open(myTasks.list[index].integration.tz)
			return true
		}
		if (statusList.indexOf(myTasks.list[index].status) + 1 === clickIndex) {
			switch (clickIndex) {
				case 9:
					setApplicationID(aplID)
					setShowChat(true)
					break
				case 2:
				case 3:
				case 5:
				case 6:
				case 7:
				case 8:
					setApplicationID(aplID)
					setShowChat(true)
					StatusUpdate(index, clickIndex)
					break
				case 4:
					setShowS3(true)
					setApplicationIndex(index)
					break
			}
		}
	}

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="myprogramms">
				<div className="content">
					<h2 className="title ftitle">Мои задания</h2>
					<div className="row">
						<div className="col-md-6">
							<form>
								<select
									onChange={(e) => {
										if (e.target.value === "story") {
											window.location.replace("/story/")
										} else {
											window.location.replace("/account/")
										}
									}}
								>
									<option value={"active"} selected={true}>
										Активные проекты
									</option>
									<option value={"story"}>Завершенные проекты</option>
								</select>
							</form>
						</div>
						<div className="col-md-6 btn-instr">
							<Link to={"https://rutube.ru/video/404e2fdaad7c42b84dddb52aa4abb092/"} target="_blank">
								Инструкция по разделу
							</Link>
						</div>
					</div>

					<div className="myprogramms-wrapper">
						{myTasks.list.length
							? myTasks.list.map((item, index) => {
									return (
										<div className="myprogramms-item">
											<h2>{item.integration.title}</h2>
											<div className="row">
												<div className="col-md-2">
													<div
														className="project-list_logo"
														style={{
															backgroundImage: `url(${host}${item.integration.productImage})`,
															cursor: "pointer",
														}}
														onClick={() => {
															setShowPopUpImage(true)
															setPopUpURL(`${host}${item.integration.productImage}`)
														}}
													></div>
												</div>
												<div className="col-md-10 desc">
													<p className="info">
														<span style={{ color: "#9B51E0" }}>Дата начала работы:</span>{" "}
														{item.date}
														<br />
														<span style={{ color: "#9B51E0" }}>Заказчик:</span>{" "}
														<Link target="_blank" to={`/seller/${item.integration.seller.pk}/`}>
															{item.integration.seller.name}
														</Link>
													</p>
													<p style={{ display: "none" }}>Описание: {item.integration.miniDesc}</p>
													<div className="myprogramms-item__btns">
														<Link
															onClick={() => {
																ym("reachGoal", "podrobnee")
																ReactGA.event({
																	category: "podrobnee",
																	action: "click",
																})
															}}
															to={`/integration/${item.integration.pk}/`}
															target="_blank"
														>
															Подробнее
														</Link>
														<span
															onClick={() => {
																ym("reachGoal", "chat_with_the_customer")
																ReactGA.event({
																	category: "chat_with_the_customer",
																	action: "click",
																})
																setApplicationID(item.pk)
																setShowChat(true)
															}}
														>
															Чат с заказчиком
														</span>
													</div>
												</div>
											</div>

											<div className="myprogramms-item__section">
												<h3>Этапы выполнения интеграции</h3>
												<ul>
													<li
														className={`${
															statusList.indexOf(item.status) === 0
																? "active_red"
																: statusList.indexOf(item.status) > 0
																? "active"
																: ""
														}`}
													>
														1 - ТЗ Принято{" "}
														<span
															onClick={() => {
																StepClick(index, 1, item.pk)
															}}
														>
															(посмотреть ТЗ)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 1
																? "active_red"
																: statusList.indexOf(item.status) > 1
																? "active"
																: ""
														}`}
													>
														2 - Ожидание реквизитов{" "}
														<span
															onClick={() => {
																StepClick(index, 2, item.pk)
															}}
														>
															(нажмите сюда после отправки номера телефона, банковских
															реквизитов и скриншота корзины в чат)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 2
																? "active_red"
																: statusList.indexOf(item.status) > 2
																? "active"
																: ""
														}`}
													>
														3 - Ожидание оплаты <span>(ожидание оплаты от селлера)</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 3
																? "active_red"
																: statusList.indexOf(item.status) > 3
																? "active"
																: ""
														}`}
													>
														4 - Оплата получена{" "}
														<span
															onClick={() => {
																StepClick(index, 4, item.pk)
															}}
														>
															(нажмите сюда <b>СТРОГО</b> если получили оплату)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 4
																? "active_red"
																: statusList.indexOf(item.status) > 4
																? "active"
																: ""
														}`}
													>
														5 - Товар заказан{" "}
														<span
															onClick={() => {
																StepClick(index, 5, item.pk)
															}}
														>
															(нажмите сюда после заказа товара и отправьте скриншот с датой
															заказа в чат)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 5
																? "active_red"
																: statusList.indexOf(item.status) > 5
																? "active"
																: ""
														}`}
													>
														6 - Товар получен{" "}
														<span
															onClick={() => {
																StepClick(index, 6, item.pk)
															}}
														>
															(нажмите сюда после получения товара. Отправьте фото товара в
															чат)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 6
																? "active_red"
																: statusList.indexOf(item.status) > 6
																? "active"
																: ""
														}`}
													>
														7 - Пост размещен{" "}
														<span
															onClick={() => {
																StepClick(index, 7, item.pk)
															}}
														>
															(нажмите сюда после размещения поста. Отправьте ссылку на пост в
															чат)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 7
																? "active_red"
																: statusList.indexOf(item.status) > 7
																? "active"
																: ""
														}`}
													>
														8 - Завершить интеграцию{" "}
														<span
															onClick={() => {
																StepClick(index, 8, item.pk)
															}}
														>
															(нажмите сюда после завершения предыдущих пунктов)
														</span>
													</li>
													<li
														className={`${
															statusList.indexOf(item.status) === 8
																? "active_red"
																: statusList.indexOf(item.status) > 8
																? "active"
																: ""
														}`}
													>
														9 - Обмен отзывами{" "}
														<span
															onClick={() => {
																if (statusList.indexOf(item.status) >= 8) {
																	setApplicationID(item.pk)
																	setShowChat(true)
																}
															}}
														>
															(обменяйтесь отзывами в чате)
														</span>
													</li>
												</ul>

												<div
													style={{
														backgroundColor: "red",
														color: "white",
														padding: 10,
														marginTop: 20,
														borderRadius: 10,
													}}
												>
													ВНИМАНИЕ! Выплата вам будет засчитана строго после прохождения всех
													этапов!
												</div>
											</div>
										</div>
									)
							  })
							: ""}
					</div>

					<Navigation countPage={myTasks.countPage} targetPage={myTasks.targetPage} clickIvent={clickIvent} />
				</div>
			</section>

			<section className="project-list" style={{ display: "none" }}>
				<div className="content">
					<h2 className="title">История выполненных заданий:</h2>
					{auth.user.applicationsStory.length ? (
						auth.user.applicationsStory.map((item) => {
							return (
								<div className="project-list__item">
									<div
										className="logo"
										style={{
											cursor: "pointer",
											backgroundImage: `url(${host.slice(0, -1)}${item.integration.seller.image})`,
										}}
									></div>
									<div className="project-list__content">
										<h3>
											{item.integration.title}
											<span>{item.date}</span>
										</h3>
										<p>{item.integration.miniDesc}</p>
									</div>
								</div>
							)
						})
					) : (
						<div>
							<h3>Список пуст</h3>
						</div>
					)}

					<div style={{ textAlign: "center" }}>
						<Link style={{ display: "inline-block" }} to="/story/" className="myprogramms__morebtnitem">
							Посмотреть все
						</Link>
					</div>
				</div>
			</section>

			<Form />

			<div className="account__setting">
				<Link to="/setting/">Редактировать профиль</Link>
			</div>

			{showS3 ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>Вы точно получили оплату?</h3>
						<div className="row">
							<div className="col-md-6">
								<div
									className="popup-msg__link"
									onClick={() => {
										setShowS3(false)
										StatusUpdate(applicationIndex, 4)
									}}
								>
									Да
								</div>
							</div>
							<div className="col-md-6">
								<div
									className="popup-msg__link"
									onClick={() => {
										setShowS3(false)
									}}
								>
									Нет
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{showPopUpImage ? <PopupImage setShowPopUpImage={setShowPopUpImage} showPopUpURL={showPopUpURL} /> : ""}

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setLoadForm(true)
								setPopupType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{showChat ? <PopupChat setShowChat={setShowChat} applicationID={applicationID} /> : ""}
		</>
	)
}
