import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Link } from "react-router-dom"
import {ApplicationList, host} from "../../../classes"
import { Loader } from "../../../component/Loader"

import ym from "react-yandex-metrika"
import ReactGA from "react-ga"

export const BlogerApplicationPage = (props: any) => {
	ReactGA.initialize("G-2HCSN416F3")

	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [application] = useState(new ApplicationList())
	const [popupType, setPopupType] = useState("")
	const [, setPopupError] = useState(true)

	useEffect(() => {
		application
			.Load(auth.user.userToken, "active")
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="application-list">
				<div className="content">
					<h2 className="title ftitle">МОИ ЗАЯВКИ:</h2>
					{application.applications.length ? (
						application.applications.map((item) => {
							return (
								<div className="myprogramms__wrapper" style={{ marginBottom: 20 }}>
									<div className="row">
										<div className="col-md-4">
											<h4 className="date">{item.date}</h4>
											<h3 className="name">{item.integration.title}</h3>
											<div className="myprogramms__logo">
												<div
													className="myprogramms__logo-img"
													style={{
														backgroundImage: `url(${host.slice(0, -1)}${
															item.integration.productImage
														})`,
													}}
												></div>
												<div className="myprogramms__logo-content">
													<div className="grid">
														<div>
															<div>{item.integration.productTitle}</div>
															{item.integration.price > 0 ? (
																<p
																	style={{
																		backgroundColor: "#f8f4ff",
																		display: "inline-block",
																		padding: "5px 14px",
																		borderRadius: 5,
																		color: "black",
																		fontSize: 14,
																		fontWeight: "500",
																	}}
																>
																	Цена: {item.integration.price} руб.
																</p>
															) : (
																""
															)}
														</div>
													</div>
												</div>
											</div>
											<Link
												target="_blank"
												to={item.integration.productLink}
												className="myprogramms__more"
											>
												Ссылка на товар
											</Link>
										</div>
										<div className="col-md-5 seller-info">
											<h3 className="myprogramms__subtitle">Заказчик</h3>
											<div className="myprogramms__logo">
												<div
													className="myprogramms__logo-img"
													style={{
														backgroundImage: `url(${host.slice(0, -1)}${
															item.integration.seller.image
														})`,
													}}
												></div>
												<div className="myprogramms__logo-content">
													<div className="grid">
														<div>
															<h4>{item.integration.seller.name}</h4>
															<p>{item.integration.seller.miniDesc}</p>
															<p className="item-desc">{item.integration.miniDesc}</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-3">
											<div className="row myprogramms__morebtn">
												{item.status === "Ожидает подтверждения от блогера" ? (
													<>
														<div className="col-12">
															<button
																className="myprogramms__more"
																onClick={() => {
																	ym("reachGoal", "to_accept")
																	ReactGA.event({
																		category: "to_accept",
																		action: "click",
																	})
																	item.Respond(auth.user.userToken, true)
																		.then(function (response) {
																			setPopupType(response.msg)
																			setPopupError(response.error)
																		})
																		.catch(function (error) {
																			alert("error")
																		})
																}}
															>
																Принять
															</button>
														</div>
														<div className="col-12">
															<button
																className="myprogramms__more"
																onClick={() => {
																	ym("95405837", "reachGoal", "reject")
																	ReactGA.event({
																		category: "reject",
																		action: "click",
																	})
																	item.Respond(auth.user.userToken, false)
																		.then(function (response) {
																			setPopupType(response.msg)
																			setPopupError(response.error)
																		})
																		.catch(function (error) {
																			setLoad(false)
																			alert("error")
																		})
																}}
																style={{ backgroundColor: "#3A0CA3" }}
															>
																Отклонить
															</button>
														</div>
													</>
												) : (
													""
												)}
												<div className="col-12">
													<Link
														to={`/integration/${item.integration.pk}/`}
														className="myprogramms__more"
														style={{ marginTop: 15, backgroundColor: "#3A0CA3" }}
													>
														Подробнее
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})
					) : (
						<div>
							<h3>Список пуст</h3>
						</div>
					)}
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setLoad(true)
								application
									.Load(auth.user.userToken, "active")
									.then(function (response) {
										setLoad(false)
									})
									.catch(function (error) {
										setLoad(false)
										alert("error")
									})
								setPopupType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
