import React, { ChangeEvent, useState } from "react"
import axios from "axios"
import { Helmet } from "react-helmet"
import { host } from "../classes"

export const ContactPage = () => {
	const [load, setLoad] = useState(true)
	const [popupType, setPopupType] = useState("")

	const [form, setForm] = useState({
		email: "",
		name: "",
		msg: "",
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (load) {
			setLoad(false)
			// let data = {
			// 	error: false,
			// 	msg: "",
			// }

			await axios
				.post(`${host}contact-form/`, {
					email: form.email,
					name: form.name,
					msg: form.msg,
				})
				.then((res) => {
					setPopupType(res.data.msg)
					setLoad(true)
					setForm({
						email: "",
						name: "",
						msg: "",
					})
				})
				.catch(function (error) {
					alert("error")
					setForm({
						email: "",
						name: "",
						msg: "",
					})
					setLoad(true)
				})
		}
	}

	return (
		<>
			<Helmet>
				<title>Информация о том как можно с нами связаться</title>
				<meta name="description" content="Форма обратной связи, юридический адрес и электронная почта." />
			</Helmet>
			<section className="contact">
				<div className="content">
					<h1 className="title">Контакты</h1>
					<div className="row">
						<div className="col-md-6 contact__info">
							<p>
								<b>E-mail: </b>
								<a href="mailto:info@blogerito.ru">info@blogerito.ru</a>
							</p>
							<p>
								<b>ИНН: </b>772875163601
							</p>
							<p>
								<b>ОГРНИП: </b>323774600399651
							</p>
							<p>
								<b>ОКТМО: </b>45378000000
							</p>
							<p>
								<b>Юридический адрес: </b>107140, РОССИЯ, г МОСКВА, ул КРАСНОПРУДНАЯ, ДОМ 22-24
							</p>
							<p>
								<b>Расчётный счёт: </b>40802810920000051368
							</p>
							<p>
								<b>Название банка: </b>ООО "Банк Точка"
							</p>
							<p>
								<b>БИК: </b>044525104
							</p>
							<p>
								<b>Корреспондентский счёт: </b>30101810745374525104
							</p>
							<p>
								<b>ИНН банка: </b>9721194461
							</p>
							<p>
								<b>КПП банка: </b>997950001
							</p>
							<p>
								<b>Юридический адрес банка: </b>109456, РОССИЯ, МОСКВА г. 1-Й ВЕШНЯКОВСКИЙ пр, ДОМ 1 СТР8, 1
								этаж, пом.№43
							</p>
						</div>
						<div className="col-md-6">
							<div className="form-item__wrapper">
								<div className="">
									<h2 style={{ color: "white" }}>Задать вопрос</h2>
									<form onSubmit={handleSubmit}>
										<label>
											<input
												type="email"
												placeholder="E-mail"
												required
												name="email"
												value={form.email}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<input
												type="text"
												placeholder="Имя"
												required
												name="name"
												value={form.name}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<textarea
												placeholder="Сообщение"
												name="msg"
												value={form.msg}
												onChange={(e) => setForm({ ...form, msg: e.target.value })}
											></textarea>
										</label>

										<button>Отправить</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setLoad(true)
								setPopupType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
