import React, { useContext, useState, useEffect, ChangeEvent } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { Link } from "react-router-dom"
import { IntegrationsList, host } from "../../../classes"
import { Loader } from "../../../component/Loader"
import axios from "axios"
import { FormatSelector } from "../../AccountPage/SellerAccountPage/FormatSelector"
import { Navigation } from "../../../component/Navigation"
import { Actual } from "../../../component/IntegrationSellerList/Actual"
import { Story } from "../../../component/IntegrationSellerList/Story"
import { PopupChat } from "../../../component/PopupChat"
import { PopupImage } from "../../../component/PopupImage"
import { getOwnInfo } from "../../../api/getOwnInfo"

export const SellerStoryPage = (props: any) => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [loadCopy, setLoadCopy] = useState(false)
	const [loadForm, setLoadForm] = useState(false)
	const [loadForm2, setLoadForm2] = useState(true)
	const [loadForm3, setLoadForm3] = useState(true)
	const [showFormat, setShowFormat] = useState(false)
	const [popupType, setPopupType] = useState("")

	const [showChat, setShowChat] = useState(false)
	const [applicationID, setApplicationID] = useState(0)

	const [showReport, setShowReport] = useState(false)
	const [reportText, setReportText] = useState("")
	const [reportType, setReportType] = useState("")
	const [reportIntegration, setReportIntegration] = useState(0)
	const [reportBloger, setReportBloger] = useState(0)
	const [isOwnSeller, setIsOwnSeller] = useState(false)

	const [showPopUpImage, setShowPopUpImage] = useState(false)
	const [showPopUpURL, setPopUpURL] = useState("")

	const [reload, setReload] = useState(true)

	const [targetItem, setTargetItem] = useState(0)

	const [showForm, setShowForm] = useState(false)

	const [integrations] = useState(new IntegrationsList())
	const [integType, setIntegType] = useState("active")

	const [fileName, setFileName] = useState("")
	const [form, setForm] = useState({
		itemName: "",
		productName: "",
		soc: "",
		miniDesc: "",
		desc: "",
		link: "",
		file: "",
		maxIntegrations: "1",
		price: 1,
		tz: "",
		format1: false,
		format2: false,
		format3: false,
		format4: false,
		format5: false,
	})

	useEffect(() => {
		integrations
			.Load(auth.user.userToken, integType, "", false, true)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
		getOwnInfo().then((response) => setIsOwnSeller(response.isOwn))
	}, [showChat])

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	function handlerImage(e: any) {
		setForm({ ...form, file: e.target.files[0] })
		setFileName(e.target.files[0].name)
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm2) {
			setLoadForm2(false)

			const formData = new FormData()

			formData.append("status", "w8_bloger")
			formData.append("title", form.itemName)
			formData.append("productTitle", form.productName)
			formData.append("productImage", form.file)
			formData.append("productLink", form.link)
			formData.append("miniDesc", form.miniDesc)
			formData.append("desc", form.desc)
			formData.append("maxIntegrations", form.maxIntegrations)
			formData.append("tz", form.tz)
			formData.append("price", `${form.price}`)

			formData.append("format1", `${form.format1}`)
			formData.append("format2", `${form.format2}`)
			formData.append("format3", `${form.format3}`)
			formData.append("format4", `${form.format4}`)
			formData.append("format5", `${form.format5}`)

			console.log(form)

			await axios
				.post(`${host}integration-setting/`, formData, {
					params: {
						id: targetItem,
					},
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Token ${auth.user.userToken}`,
					},
				})
				.then((res) => {
					console.log("tes")
					setPopupType(res.data.msg)
				})
				.catch((error) => {
					console.log(error)
					alert("error")
				})
		}
	}

	const clickIvent = (target: number) => {
		integrations.targetPage = target
		setLoad(true)
		integrations
			.Load(auth.user.userToken, integType, "", false, true)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const CopyItem = (pk: number) => {
		if (!loadCopy) {
			setLoadCopy(true)
			integrations
				.Copy(auth.user.userToken, pk)
				.then(function (response) {
					setLoadCopy(false)
					window.location.reload()
				})
				.catch(function (error) {
					setLoadCopy(false)
					alert("error")
				})
		}
	}

	const RecoverItem = (pk: number) => {
		if (!loadCopy) {
			setLoadCopy(true)
			integrations
				.Recover(auth.user.userToken, pk)
				.then(function (response) {
					setLoadCopy(false)
					window.location.reload()
				})
				.catch(function (error) {
					setLoadCopy(false)
					alert("error")
				})
		}
	}

	const CloseItem = (pk: number) => {
		if (!loadCopy) {
			setLoadCopy(true)
			integrations
				.Close(auth.user.userToken, pk)
				.then(function (response) {
					setLoadCopy(false)
					window.location.reload()
				})
				.catch(function (error) {
					setLoadCopy(false)
					alert("error")
				})
		}
	}

	const EditItem = (item: any) => {
		setShowForm(true)
		setLoadForm(true)
		setTargetItem(item.pk)
		item.LoadStat(auth.user.userToken, item.pk)
			.then(function (response: any) {
				console.log(response)
				setForm({
					itemName: response.msg.itemName,
					productName: response.msg.productName,
					soc: response.msg.soc,
					miniDesc: response.msg.miniDesc,
					desc: response.msg.desc,
					link: response.msg.link,
					file: "",
					price: response.msg.price,
					maxIntegrations: response.msg.maxIntegrations,
					tz: response.msg.tz,
					format1: response.msg.format1,
					format2: response.msg.format2,
					format3: response.msg.format3,
					format4: response.msg.format4,
					format5: response.msg.format5,
				})
				setLoadForm(false)
			})
			.catch(function (error: any) {
				alert("error")
			})
	}

	const PayConf = async (pk: number, integration: number, amount?: number) => {
		integrations.PayConf(pk, auth.user.userToken, integration, amount).then(function (respond) {
			setPopupType("Статус успешно обновлен")
			setReload(false)
		})
	}

	const RespondItem = (pk: number, status: boolean, blogerId: number, index: number) => {
		setReload(false)
		integrations
			.Respond(auth.user.userToken, status, pk, blogerId, index)
			.then(function (response) {
				setPopupType(response.msg)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const SendError = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm3) {
			setLoadForm3(false)
			console.log({
				bloger: reportBloger,
				integration: reportIntegration,
				reportsType: reportType,
				minidesc: reportText,
			})
			console.log(auth.user.userToken)
			await axios
				.post(
					`${host}reports/`,
					{
						bloger: reportBloger,
						integration: reportIntegration,
						reportsType: reportType,
						minidesc: reportText,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Token ${auth.user.userToken}`,
						},
					},
				)
				.then((res) => {
					setPopupType(res.data.msg)
					setReload(false)
					setLoadForm3(true)
					setShowReport(false)
				})
				.catch((error) => {
					setLoadForm3(true)
					console.log(error)
				})
		}
	}

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="myprogramms">
				<div className="content">
					<h2 className="title ftitle">Мои проекты</h2>
					<div className="row">
						<div className="col-md-6">
							<form>
								<select
									onChange={(e) => {
										setIntegType(e.target.value)
										setLoad(true)
										integrations
											.Load(
												auth.user.userToken,
												integType === "active" ? "story" : "active",
												"",
												false,
												true,
											)
											.then(function (response) {
												setLoad(false)
											})
											.catch(function (error) {
												alert("error")
											})
									}}
								>
									<option value={"active"} selected={integType === "active"}>
										Активные проекты
									</option>
									<option value={"story"} selected={integType === "story"}>
										Завершенные проекты
									</option>
								</select>
							</form>
						</div>
						<div className="col-md-6 btn-instr">
							<Link to={"https://rutube.ru/video/f2804b7f5ff17ae185623b76c9d1bf90/"} target="_blank">
								Инструкция по разделу
							</Link>
						</div>
					</div>
					{integrations.applications.length ? (
						integrations.applications.map((item: any, index: number) => {
							return (
								<>
									{integType === "active" ? (
										<Actual
											index={index}
											item={item}
											integrations={integrations}
											CopyItem={CopyItem}
											CloseItem={CloseItem}
											EditItem={EditItem}
											RespondItem={RespondItem}
											setShowReport={setShowReport}
											setReportType={setReportType}
											setReportBloger={setReportBloger}
											setReportIntegration={setReportIntegration}
											setApplicationID={setApplicationID}
											setShowChat={setShowChat}
											setPopupType={setPopupType}
											setReload={setReload}
											PayConf={PayConf}
											setShowPopUpImage={setShowPopUpImage}
											setPopUpURL={setPopUpURL}
											isOwnSeller={isOwnSeller}
										/>
									) : (
										<Story
											item={item}
											CopyItem={CopyItem}
											RecoverItem={RecoverItem}
											setShowPopUpImage={setShowPopUpImage}
											setPopUpURL={setPopUpURL}
										/>
									)}
								</>
							)
						})
					) : (
						<div>
							<h3>Список пуст</h3>
						</div>
					)}
					<Navigation
						countPage={integrations.countPage}
						targetPage={integrations.targetPage}
						clickIvent={clickIvent}
					/>
				</div>
			</section>

			{showForm ? (
				<div className="popup-integration">
					<div className="">
						<div style={{ textAlign: "right" }}>
							<svg
								onClick={() => {
									setShowForm(false)
								}}
								width="40px"
								height="40px"
								viewBox="0 0 1024 1024"
							>
								<path
									fill="#fff"
									d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
								/>
							</svg>
						</div>
						{loadForm ? (
							<div style={{ backgroundColor: "white", borderRadius: 10, paddingTop: 20 }}>
								<Loader />
							</div>
						) : (
							<form onSubmit={handleSubmit}>
								<label>
									Название интеграции
									<input
										type="text"
										maxLength={255}
										required
										style={{ backgroundColor: "white !important", color: "black !important" }}
										name="itemName"
										value={form.itemName}
										onChange={changeHandler}
									/>
								</label>
								<label>
									Название товара
									<input
										type="text"
										maxLength={255}
										required
										name="productName"
										value={form.productName}
										onChange={changeHandler}
									/>
								</label>
								<label>
									Количество возможных интеграций в рамках этого задания
									<input
										required
										type="number"
										min={form.maxIntegrations}
										step={1}
										value={form.maxIntegrations}
										name="maxIntegrations"
										onChange={changeHandler}
									/>
								</label>
								<label style={{ display: "none" }}>
									Социальная сеть
									<select
										required
										onChange={(e) => {
											setForm({ ...form, soc: e.target.value })
										}}
									>
										<option value="" disabled>
											Выбрать
										</option>
										<option value={"instagram"} selected>
											Instagram
										</option>
										<option value={"youtube"}>Youtube</option>
										<option value={"vk"}>Vk</option>
										<option value={"rutube"}>Rutube</option>
										<option value={"telegram"}>Telegram</option>
										<option value={"any"}>Любая</option>
									</select>
								</label>

								<FormatSelector
									form={form}
									setForm={setForm}
									showFormat={showFormat}
									setShowFormat={setShowFormat}
								/>

								<label>
									Короткое описание
									<input
										type="text"
										maxLength={510}
										required
										name="miniDesc"
										value={form.miniDesc}
										onChange={changeHandler}
									/>
								</label>

								<label>
									Цена товара <b style={{ fontSize: 12, fontWeight: 300 }}>(руб.)</b>
									<input
										type="number"
										name="price"
										required
										value={form.price}
										min={1}
										onChange={changeHandler}
									/>
								</label>

								<label>
									Описание и ваши пожелания
									<textarea
										required
										name="msg"
										value={form.desc}
										onChange={(e) => setForm({ ...form, desc: e.target.value })}
									/>
								</label>

								<label>
									Техническое задание на покупку товара{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>
										(будет автоматически выслано блогеру после приема заявки)
									</b>
									<input
										type="text"
										maxLength={510}
										required
										name="tz"
										value={form.tz}
										onChange={changeHandler}
									/>
								</label>

								<label>
									Ссылка на рекламируемую страницу{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>(можно оставить пустым)</b>
									<input
										type="text"
										maxLength={510}
										name="link"
										value={form.link}
										onChange={changeHandler}
									/>
								</label>

								{form.file === "" ? (
									<label style={{ cursor: "pointer" }}>
										<div className="dop-file project">
											Прикрепите фото товара для его изменения
											<input type="file" name="file" onChange={handlerImage} accept="image/*" />
										</div>
									</label>
								) : (
									<div>
										<div className="dop-file project">
											{fileName}
											<span
												onClick={() => {
													setForm({ ...form, file: "" })
													setFileName("")
												}}
												style={{
													color: "red",
													display: "inline-block",
													width: "auto",
													paddingLeft: "10px",
													cursor: "pointer",
												}}
											>
												X
											</span>
										</div>
									</div>
								)}
								<button>Обновить интеграцию</button>
							</form>
						)}
					</div>
				</div>
			) : (
				""
			)}

			{showPopUpImage ? <PopupImage setShowPopUpImage={setShowPopUpImage} showPopUpURL={showPopUpURL} /> : ""}

			{showReport ? (
				<div className="popupChat">
					<div>
						<h3>Опишите причину обращения</h3>
						<form onSubmit={SendError}>
							<textarea
								required
								placeholder={reportType === "integration" ? "Кто-то сделал возврат и др." : ""}
								className="cancel-text"
								value={reportText}
								onChange={(e) => setReportText(e.target.value)}
							/>
							<div>
								<span
									style={{
										marginRight: 20,
										fontSize: 12,
										color: "rgba(0,0,0,0.4)",
										cursor: "pointer",
									}}
									onClick={() => {
										setShowReport(false)
									}}
								>
									Закрыть
								</span>
								<button style={{ backgroundColor: "rgb(58, 12, 163)" }}>Отправить</button>
							</div>
						</form>
					</div>
				</div>
			) : (
				""
			)}

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setPopupType("")
								if (reload) window.location.reload()

								setReload(true)
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{showChat ? <PopupChat setShowChat={setShowChat} applicationID={applicationID} /> : ""}
		</>
	)
}
