import { useEffect, useState } from "react"
import {Dashbord, host} from "../classes"
import { Loader } from "../component/Loader"

export const DashbordPage = () => {
	const [load, setLoad] = useState(true)
	const [showDashVideo, setShowDashVideo] = useState(false)
	const [dashbord] = useState(new Dashbord())
	const [target, setTarget] = useState(0)
	const [complite, setComplite] = useState([-1])

	const AddToComp = (id: number) => {
		if (complite!.indexOf(id) === -1) {
			const hArray = complite
			hArray.push(Number(id))
			setComplite(hArray)
			localStorage.setItem(
				"userDashbord",
				JSON.stringify({
					count: 10,
					complite: hArray,
				}),
			)
		}
	}

	useEffect(() => {
		if (!localStorage.getItem("userDashbord")) {
			localStorage.setItem(
				"userDashbord",
				JSON.stringify({
					count: 10,
					complite: [],
				}),
			)
		} else {
			const userStorage = JSON.parse(localStorage.getItem("userDashbord") || "")
			setComplite(userStorage.complite)
		}

		dashbord
			.getData()
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="dashbord-lessons">
				<div className="content">
					<h1 className="title">Активные уроки</h1>
					<div className="row dashbord-lessons__wrapper">
						{dashbord.list.length
							? dashbord.list.map((item, index) => {
									return (
										<div key={index} className="col-md-3 col-md-five">
											<div
												onClick={() => {
													AddToComp(item.pk)
													setShowDashVideo(true)
													setTarget(index)
												}}
												className="dashbord-lessons__item"
											>
												<div
													className="dashbord-lessons__img"
													style={{
														backgroundImage: `url('${host.slice(0, -1)}${item.image}')`,
													}}
												></div>
												<h3>{item.title}</h3>
												<p>
													{complite!.indexOf(item.pk) !== -1 ? (
														<span style={{ color: "green" }}>Урок пройден</span>
													) : (
														<span>Урок не пройден</span>
													)}
												</p>
											</div>
										</div>
									)
							  })
							: ""}
					</div>
				</div>
			</section>

			{showDashVideo ? (
				<div className="dashbord-popup less">
					<div>
						<h2>{dashbord.list[target].title}</h2>
						<div
							className="popup-lesson__desc"
							dangerouslySetInnerHTML={{ __html: dashbord.list[target].desc }}
						/>
						<div className="popup-lesson__btns">
							<div className="row">
								<div className="col-md-6">
									<div
										onClick={() => {
											AddToComp(target + 1)
											setShowDashVideo(false)
										}}
										style={{
											color: "#ababab",
											fontSize: 14,
											textDecoration: "underline",
											marginTop: 9,
											cursor: "pointer",
										}}
									>
										Закрыть
									</div>
								</div>
								<div className="col-md-6">
									<button
										onClick={() => {
											if (target < dashbord.list.length - 1) {
												AddToComp(target + 1)
												setTarget(target + 1)
											} else {
												AddToComp(target + 1)
												setShowDashVideo(false)
											}
										}}
									>
										Далее
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
