import React, { useContext } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { BlogerAccountPage } from "./BlogerAccountPage/BlogerAccountPage"
import { SellerAccountPage } from "./SellerAccountPage/SellerAccountPage"

export const AccountPage = () => {
	const auth = useContext(GlobalContext)

	// console.log(auth.user.userToken)
	return <>{auth.user.userType === "bloger" ? <BlogerAccountPage /> : <SellerAccountPage />}</>
}
