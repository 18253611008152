import { Link } from "react-router-dom"
import { host } from "../../classes"

export const Story = (props: any) => {
	return (
		<div className="project-list__item">
			<div
				className="logo"
				style={{
					cursor: "pointer",
					backgroundImage: `url(${host.slice(0, -1)}${props.item.productImage})`,
				}}
				onClick={() => {
					props.setShowPopUpImage(true)
					props.setPopUpURL(`${host.slice(0, -1)}${props.item.productImage}`)
				}}
			></div>
			<div className="project-list__content">
				<h3>
					<Link to={`/integration/${props.item.pk}/`}>{props.item.title}</Link>
				</h3>
				<p
					style={{
						fontWeight: 600,
						fontSize: "16px",
						marginTop: "-10px",
						paddingBottom: "5px",
						color: "black",
					}}
				>
					Заявок от блогеров: {props.item.applicationCount}
				</p>
				<div className="project-list__item-cont">
					<p>
						<b>Дата создания: </b>
						{props.item.date}
					</p>
					<p>
						<b>Статус: </b>
						{props.item.status}
					</p>
					<p>
						<b>Количество товаров: </b>
						{props.item.integrationsCount}
					</p>
					<p>
						<b>В работе: </b>
						{props.item.applications_list.length}
					</p>
					<p>{props.item.miniDesc}</p>
				</div>

				<div className="project-btns">
					<button
						onClick={() => {
							props.CopyItem(props.item.pk)
						}}
					>
						Копировать
					</button>

					<button
						style={{
							backgroundColor: "#3A0CA3",
						}}
						onClick={() => {
							props.RecoverItem(props.item.pk)
						}}
					>
						Восстановить
					</button>
				</div>
			</div>
		</div>
	)
}
