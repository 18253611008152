import { Link } from "react-router-dom"
import icon1 from "../image/icon.svg"
import icon2 from "../image/icon-2.svg"
import icon3 from "../image/icon-3.svg"
import icon4 from "../image/icon-4.svg"
import icon5 from "../image/icon-5.svg"
import icon6 from "../image/icon-6.svg"
import bannerHuman from "../image/banner_human.png"
import whyHuman from "../image/why_human.png"

import example1 from "../image/example_1.png"
import example2 from "../image/example_2.png"
import example3 from "../image/example_3.png"
import example4 from "../image/example_4.png"

import seller01 from "../image/seller_01.png"
import seller02 from "../image/seller_02.png"
import seller03 from "../image/seller_03.png"
import seller04 from "../image/seller_04.png"

import workO1_01 from "../image/work_01_1.jpeg"
import workO1_02 from "../image/work_01_2.jpeg"
import workO1_03 from "../image/work_01_3.jpeg"
import workO1_04 from "../image/work_01_4.jpeg"
import workO1_05 from "../image/work_01_5.jpeg"
import workO1_06 from "../image/work_01_6.jpeg"

import workO2_01 from "../image/work02_01.jpeg"
import workO2_02 from "../image/work02_02.jpeg"
import workO2_03 from "../image/work02_03.jpeg"
import workO2_04 from "../image/work02_04.jpeg"
import workO2_05 from "../image/work02_05.jpeg"
import workO2_06 from "../image/work02_06.jpeg"
import workO2_07 from "../image/work02_07.jpeg"
import workO2_08 from "../image/work02_08.jpeg"
import workO2_09 from "../image/work02_09.jpeg"
import workO2_10 from "../image/work02_10.jpeg"

import { Helmet } from "react-helmet"
import { BlogerWork } from "../component/BlogerWork"
import blogerpholder from "../image/bloger-pholder.png"
import blogerpholder2 from "../image/bloger-pholder2.png"
// import workitem from "../image/workitem.png"
import every from "../image/every.png"
import ym from "react-yandex-metrika"
import { useEffect } from "react"

export const BlogerPage = () => {
	useEffect(() => {
		const array = window.location.href.split("/") // TODO: improve
		console.log(array[3])

		if (array[3] === "bloger") {
			if (localStorage.getItem("userBlogerFlag")) {
				window.location.replace("/blogers/")
			}
		}

		if (array[3] === "blogers") {
			localStorage.setItem(
				"userBlogerFlag",
				JSON.stringify({
					flag: true,
				}),
			)
		}
	}, [])

	return (
		<>
			<Helmet>
				<title>Получай товары бесплатно для себя и своих подписчиков в обмен на посты</title>
				<meta
					name="description"
					content="Региструйся сейчас, и мы поможем получить эксклюзивные предложения от продавцов маркетплейсов. Различные категории товаров от более сотен продавцов маркетплейсов."
				/>
			</Helmet>
			<section className="banner bloger-banner">
				<div className="content">
					<div className="row">
						<div className="col-md-8">
							<div className="banner__content">
								<h1>
									Получай товары на <span style={{ color: "rgb(155, 81, 224)" }}>15&#160;000</span> рублей
									в месяц и более бесплатно в обмен на посты в соцсетях
								</h1>
								<h2>
									используй свои соцсети либо начни вести блог - <br />
									продавцы маркетплейсов уже готовы к сотрудничеству со всеми у кого более 100 друзей или
									подписчиков в VK,TG и других соцсетях
								</h2>
								<ul className="banner_soc">
									<li>
										<img src={icon1} alt="" />
									</li>
									<li>
										<img src={icon2} alt="" />
									</li>
									<li>
										<img src={icon3} alt="" />
									</li>
									<li>
										<img src={icon4} alt="" />
									</li>
									<li>
										<img src={icon5} alt="" />
									</li>
									<li>
										<img src={icon6} alt="" />
									</li>
								</ul>

								<div className="banner__btns">
									<Link
										to="/registration/"
										onClick={() => {
											ym("reachGoal", "registration")
										}}
									>
										Регистрация
									</Link>
									<Link
										to="/login/"
										onClick={() => {
											ym("reachGoal", "enter")
										}}
										style={{ backgroundColor: "#9B51E0" }}
									>
										Вход
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="grid center">
								<img className="banner_human" src={bannerHuman} alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="bloger-video">
				<div className="content">
					<h2 className="title title_type2">
						как работает <span>сервис</span>
					</h2>
					<iframe
						width="560"
						height="315"
						src="https://rutube.ru/play/embed/58e371af1232c2c46c16a14f3ae85c03/"
						title="YouTube video player"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					></iframe>
				</div>
			</section>

			<section className="bloger-works">
				<div className="content">
					<h2 className="title title_type2">
						Уже более <span>1000</span> человек
					</h2>
					<p className="subtitle">размещают посты И ПОЛУЧАЮТ ВЫГОДУ</p>

					<BlogerWork
						image={blogerpholder}
						name={"Арина Михайловна"}
						uDesc={"29 лет Москва"}
						bodyText={
							<>
								<div className="bloger-works__text">
									<h4>Как часто вы находите на Blogerito для себя интересные предложения по бартеру?</h4>
									<p>
										<span>Арина:</span> Каждый день. Последнее из интересных - это интерьерная картина, я
										ее даже в предложениях не видела.
									</p>
								</div>
								<div className="bloger-works__text">
									<h4>Какие товары вам удалось получить в рамках интеграций за последние 2 недели?</h4>
									<p>
										<span>Арина:</span> За последние две недели, у меня по интеграциям были: Футболка,
										конфеты, отпариватель, вафельница, картина, набор для настоек, костюм, шлепанцы,
										массажер для лица, декоративная косметика.
									</p>
								</div>
							</>
						}
						images={[workO1_01, workO1_02, workO1_03, workO1_04, workO1_05, workO1_06]}
					/>

					<BlogerWork
						image={blogerpholder2}
						name={"Кристина Морская"}
						uDesc={"30 лет Анапа"}
						bodyText={
							<div className="bloger-works__text">
								<h4>Какие товары вам удалось получить в рамках интеграций за последние 2 недели?</h4>
								<p>
									<span>Кристина:</span> За последние две недели, у меня по интеграциям были: шоппер, два
									костюма, костюм оверсайз с шортами, платье и леопардовый костюм в одной интеграции,
									купальник, микротоковый массажер, карта желаний, косметические тейпы, бокс от
									Емельяновской биофабрики, бокс косметики спа набор, приобретение для кота, набор
									косметики от TF, интерактивная музыкальная груша😅возможно что-то забыла. Товаров много,
									работы полно😊
								</p>
							</div>
						}
						images={[
							workO2_01,
							workO2_02,
							workO2_03,
							workO2_04,
							workO2_05,
							workO2_06,
							workO2_07,
							workO2_08,
							workO2_09,
							workO2_10,
						]}
					/>
				</div>
			</section>

			<section className="why-blogerito bloger-wh">
				<div>
					<div className="content">
						<h2 className="title title_type2">Как начать ?</h2>
						<div className="row why-blogerito__list">
							<div className="col-lg-8">
								<div className="row">
									<div className="col-md-6">
										<div className="why-blogerito__item">
											<div className="why-blogerito__image-wrapper">
												<div className="why-blogerito__image" style={{ backgroundColor: "#4361EE" }}>
													1
												</div>
												<div className="why-blogerito__title">
													<div className="grid"></div>
												</div>
											</div>
											<p>
												Зарегистрируйтесь
												<br />в нашем сервисе
											</p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="why-blogerito__item">
											<div className="why-blogerito__image-wrapper">
												<div className="why-blogerito__image" style={{ backgroundColor: "#4361EE" }}>
													2
												</div>
												<div className="why-blogerito__title">
													<div className="grid"></div>
												</div>
											</div>
											<p>Заполните профиль, информацию о себе и своих соцсетях</p>
										</div>
									</div>

									<div className="col-md-6">
										<div className="why-blogerito__item">
											<div className="why-blogerito__image-wrapper">
												<div className="why-blogerito__image" style={{ backgroundColor: "#4361EE" }}>
													3
												</div>
												<div className="why-blogerito__title">
													<div className="grid"></div>
												</div>
											</div>
											<p>
												Получайте заявки на интеграцию через телеграмм либо посредством личного
												кабинета блогера на сайте
											</p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="why-blogerito__item">
											<div className="why-blogerito__image-wrapper">
												<div className="why-blogerito__image" style={{ backgroundColor: "#4361EE" }}>
													4
												</div>
												<div className="why-blogerito__title">
													<div className="grid"></div>
												</div>
											</div>
											<p>
												Успешно работайте и повышайте рейтинг для извлечения максимальной выгоды из
												Blogerito
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<img src={whyHuman} className="why-human" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="every-day">
				<div className="content">
					<div className="every-day__wrapper">
						<div>
							<h3>Каждый день</h3>
							<p style={{ marginBottom: 20, opacity: 0.6 }}>
								в нашем сервисе размещается несколько десятков товаров из различных категорий:
							</p>
							<p>одежда, косметика, товары для дома, игрушки, текстиль, парфюмерия и многое другое.</p>
						</div>
						<img src={every} alt="" />
					</div>
				</div>
			</section>

			<section className="bloger-examples">
				<div className="content">
					<h2 className="title title_type2">Примеры предложений от селлеров:</h2>

					<div className="bloger-examples__item">
						<div className="row">
							<div className="col-md-7">
								<div className="grid">
									<div className="rblock">
										<div className="bloger-examples__seller">Заказчик</div>
										<div className="bloger-examples__image">
											<div className="image">
												<div style={{ backgroundImage: `url('${seller01}')` }}></div>
											</div>
											<div className="text">
												<div className="grid">
													<h3>Михаил</h3>
												</div>
											</div>
										</div>
										<h2>
											Ароматизатор для дома
											<br />
											диффузор Табак Ваниль
										</h2>
										<p>
											Предлагаем ароматический диффузор с палочками для дома, аромат Хлопок в обмен на
											отзыв и рекламный пост в вашем блоге
										</p>
										<div className="bloger-examples__price">
											<div>
												Стоимость товара{" "}
												<span>
													<s>1 108 ₽</s>
												</span>
											</div>
											<div>
												Стоимость по бартеру <span>0 ₽</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col">
								<div
									className="bloger-examples__product"
									style={{
										backgroundImage: `url('${example1}')`,
									}}
								></div>
							</div>
						</div>
					</div>

					<div className="bloger-examples__item">
						<div className="row">
							<div className="col order-2 order-md-1">
								<div
									className="bloger-examples__product"
									style={{
										backgroundImage: `url('${example2}')`,
									}}
								></div>
							</div>
							<div className="col-md-7 order-1 order-md-2">
								<div className="grid">
									<div className="lblock">
										<div className="bloger-examples__seller">Заказчик</div>
										<div className="bloger-examples__image">
											<div className="image">
												<div style={{ backgroundImage: `url('${seller02}')` }}></div>
											</div>
											<div className="text">
												<div className="grid">
													<h3>Снежана</h3>
												</div>
											</div>
										</div>
										<h2>
											Сушилка ручная механическая
											<br />
											для зелени, центрифуга для сушки
										</h2>
										<p>
											Хотите механическую сушилку для зелени? Предлагаем по бартеру, идеально подойдет
											для шпината, руколлы и многого другого. С вас рекламный пост и отзыв.
										</p>
										<div className="bloger-examples__price">
											<div>
												Стоимость товара{" "}
												<span>
													<s>1 006 ₽</s>
												</span>
											</div>
											<div>
												Стоимость по бартеру <span>0 ₽</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="bloger-examples__item">
						<div className="row">
							<div className="col-md-7">
								<div className="grid">
									<div className="rblock">
										<div className="bloger-examples__seller">Заказчик</div>
										<div className="bloger-examples__image">
											<div className="image">
												<div style={{ backgroundImage: `url('${seller03}')` }}></div>
											</div>
											<div className="text">
												<div className="grid">
													<h3>Александр</h3>
												</div>
											</div>
										</div>
										<h2>Шариковый ролик массажер для тела</h2>
										<p>
											Классный массажный ролик поможет снять стресс и скорректирует фигуру. Предлагаем
											в обмен на отзыв и рекламу.
										</p>
										<div className="bloger-examples__price">
											<div>
												Стоимость товара{" "}
												<span>
													<s>481 ₽</s>
												</span>
											</div>
											<div>
												Стоимость по бартеру <span>0 ₽</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col">
								<div
									className="bloger-examples__product"
									style={{
										backgroundImage: `url('${example3}')`,
									}}
								></div>
							</div>
						</div>
					</div>

					<div className="bloger-examples__item">
						<div className="row">
							<div className="col order-2 order-md-1">
								<div
									className="bloger-examples__product"
									style={{
										backgroundImage: `url('${example4}')`,
									}}
								></div>
							</div>
							<div className="col-md-7 order-1 order-md-2">
								<div className="grid">
									<div className="lblock">
										<div className="bloger-examples__seller">Заказчик</div>
										<div className="bloger-examples__image">
											<div className="image">
												<div style={{ backgroundImage: `url('${seller04}')` }}></div>
											</div>
											<div className="text">
												<div className="grid">
													<h3>Светлана</h3>
												</div>
											</div>
										</div>
										<h2>Вечный блокнот для записей на кольцах с ручкой</h2>
										<p>
											Больше не надо покупать десятки блокнотов и тетрадей - используйте вечный блокнот
											- сканируйте и оцифровывайте ваши записи, затем стирайте листы влажной тряпочкой
											и пишите заново. Предлагаем по бартеру в обмен на отзыв и размещение рекламы в
											вашем блоге.
										</p>
										<div className="bloger-examples__price">
											<div>
												Стоимость товара{" "}
												<span>
													<s>970 ₽</s>
												</span>
											</div>
											<div>
												Стоимость по бартеру <span>0 ₽</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="start-work bloger-work">
				<div className="content">
					<div className="start-work__wrapper">
						<div className="row">
							<div className="col-md-6">
								<h2>
									Найди свой
									<br />
									первый бартер
								</h2>
								<p>
									регистрируйся сейчас, и мы поможем получить эксклюзивные предложения от продавцов
									маркетплейсов
								</p>
								<Link
									to={"/registration/"}
									onClick={() => {
										ym("reachGoal", "registration")
									}}
								>
									Регистрация
								</Link>
							</div>
							<div className="col-md-6">
								<h2>
									Остались
									<br />
									вопросы ?
								</h2>
								<p>Напиши нам в Телеграм. Расскажем все о сервисе</p>
								<Link
									to={"https://telegram.me/cathatrisha"}
									target="_blank"
									style={{
										backgroundColor: " #459AD5",
										paddingRight: "130px",
									}}
									onClick={() => {
										ym("reachGoal", "telegram")
									}}
								>
									Написать нам
									<svg
										width="42"
										height="42"
										viewBox="0 0 42 42"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle cx="21" cy="21" r="21" fill="#3A0CA3" />
										<g clip-path="url(#clip0_363_1709)">
											<path
												d="M27.0092 13.5041C27.0092 13.5041 28.7753 12.8155 28.6282 14.4879C28.5791 15.1765 28.1376 17.5868 27.7942 20.1938L26.6168 27.9163C26.6168 27.9163 26.5187 29.0477 25.6357 29.2444C24.7526 29.4412 23.4281 28.5558 23.1828 28.359C22.9866 28.2115 19.5035 25.998 18.2771 24.9159C17.9337 24.6207 17.5412 24.0304 18.3262 23.3418L23.4772 18.423C24.0659 17.8327 24.6545 16.4555 22.2017 18.1278L15.3337 22.8007C15.3337 22.8007 14.5488 23.2926 13.0771 22.8499L9.88837 21.8662C9.88837 21.8662 8.71099 21.1284 10.7223 20.3905C15.6281 18.0786 21.6621 15.7175 27.0092 13.5041Z"
												fill="white"
											/>
										</g>
										<defs>
											<clipPath id="clip0_363_1709">
												<rect
													width="38.1818"
													height="38.1818"
													fill="white"
													transform="translate(0 2.29089)"
												/>
											</clipPath>
										</defs>
									</svg>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
