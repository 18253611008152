import React from "react"

export const Rules = () => {
	return (
		<section className="privacy">
			<div className="content">
				<h1 className="title">Внутренние правила Платформы</h1>
				<h3>
					1. ЗАКЛЮЧЕНИЕ И ИСПОЛНЕНИЕ ДОГОВОРА НА РАЗМЕЩЕНИЕ ОБЪЯВЛЕНИЯ О ТОВАРЕ ПОЛЬЗОВАТЕЛЯ В РАМКАХ ИНТЕГРАЦИИ.
				</h3>
				<p>
					<strong>1.1.</strong> Заключение договора на размещение информации о товаре между Пользователями
					осуществляется самостоятельно и в соответствии с законодательством Российской Федерации.
				</p>

				<p>
					<strong>1.2.</strong> Срок, стоимость и иные условия Интеграции и Договора на размещение информации о
					товаре Пользователями согласуются между собой путем коммуникации на Платформе без участия
					Правообладателя.
				</p>

				<p>
					<strong>1.3.</strong> Пользователи соглашаются, что Правообладатель не является стороной договорных
					правоотношений между ними, в связи с чем Правообладатель:
				</p>
				<ul>
					<li>
						не проверяет и не отвечает за соответствие договорных правоотношений требованиям законодательства
						Российской Федерации, не контролирует ход исполнения обязательств, вытекающих из Договора;
					</li>
					<li>
						не согласует условия Договоров на размещение информации о товаре Пользователей, не дает им правовую
						оценку;
					</li>
					<li>не вмешивается в договорные правоотношения, возникающие между Пользователями;</li>
					<li>
						не является арбитром в регулировании возможных споров и претензий, возникающих в результате
						договорных правоотношений.
					</li>
				</ul>

				<p>
					<strong>1.4.</strong> При согласовании условий Договора на размещение информации о товаре Пользователи
					самостоятельно определяют, является ли информация, размещаемая в Социальных сетях в рамках Интеграции,
					рекламой в значении Федерального закона «О рекламе» от 13.03.2006 N 38-ФЗ для соблюдения требований о
					маркировке рекламы в Интернете.
				</p>

				<p>
					<strong>1.5.</strong> Правообладатель ни при каких обстоятельствах не отвечает за передачу информации о
					рекламе и не является участником в сделках, совершаемых Пользователями.
				</p>
				<h3>2. ВЗАИМОДЕЙСТВИЕ МЕЖДУ ПОЛЬЗОВАТЕЛЕМ И БЛОГЕРОМ</h3>
				<p>
					<strong>2.1.</strong> Пользователи соглашаются, что взаимодействуют между собой в чатах на Платформе, а
					также в специальном боте в социальной сети Telegram, созданном Правообладателем. Пользователям запрещено
					обмениваться ссылками, которые ведут на их сторонние аккаунты.
				</p>

				<p>
					<strong>2.2.</strong> При использовании Платформы Пользователи могут загружать на Платформу
					Пользовательский контент в пределах, предусмотренных функционалом Платформы.
				</p>

				<p>
					<strong>2.3.</strong> Пользователь несет единоличную ответственность за любые материалы и информацию,
					которую он загружает или иным образом доводит до сведения иных пользователей и (или) Правообладателя на
					Платформе или с ее помощью. Пользователь не имеет права загружать, передавать или публиковать информацию
					на Платформе, если у него нет приобретенных или переданных ему соответствующих прав на такую информацию.
				</p>

				<p>
					<strong>2.4.</strong> Правообладатель может, но не обязан, просматривать (модерировать) Платформу на
					наличие запрещенного контента и может скрывать (без предупреждения) любой контент по любой причине или
					без причины, в том числе тот, который, по личному мнению Правообладателя, нарушает Договор и (или) может
					нарушать права, причинять вред или угрожать безопасности других Пользователей или третьих лиц.
				</p>

				<p>
					<strong>2.5.</strong> Запрещается использование Пользователем Платформы в непредназначенных для этого
					целях, например, поднятия рейтинга карточки за счет добавления блогерами товара в корзину с последующей
					отменой интеграции. Отмена интеграции без объективных причин будет считаться нарушением правил Платформы.
				</p>

				<p>
					<strong>2.6.</strong> Пользователь обязан корректно и уважительно общаться с Блогерами на Платформе. В
					случае поступления жалоб со стороны Блогеров, а также при подозрениях в нецелевом использовании Платформы
					Пользователем, Правообладатель оставляет за собой право ограничить использование Пользователем Платформы.
				</p>
			</div>
		</section>
	)
}
