import React, { ChangeEvent, useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { Link } from "react-router-dom"
import { host, IntegrationsList, JobsApplications } from "../../classes"
import { Loader } from "../../component/Loader"
import { Navigation } from "../../component/Navigation"
import { PopupImage } from "../../component/PopupImage"
import styles from "./styles.module.css"
import ym from "react-yandex-metrika"
import ReactGA from "react-ga"
import { statuses } from "../../constants/statuses"
import classNames from "classnames"

export const IntegrationsListPage = (props: any) => {
	ReactGA.initialize("G-2HCSN416F3")

	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [integrations] = useState(new IntegrationsList())
	const [search, setSearch] = useState("")
	const [loadBtn, setLoadBtn] = useState(false)
	const [popupType, setPopupType] = useState("")
	const [, setPopupError] = useState(true)

	const [showPopUpImage, setShowPopUpImage] = useState(false)
	const [showPopUpURL, setPopUpURL] = useState("")

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!load) {
			setLoad(true)
			integrations
				.Load(auth.user.userToken, "active", search, true, false, true)
				.then(function (response) {
					setLoad(false)
				})
				.catch(function (error) {
					alert("error")
				})
		}
	}

	useEffect(() => {
		integrations
			.Load(auth.user.userToken, "active", search, true, false, true)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	const clickIvent = (target: number) => {
		integrations.targetPage = target
		setLoad(true)
		integrations
			.Load(auth.user.userToken, "active", search)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	if (load) {
		return <Loader />
	}
	return (
		<>
			<section className="integrations-list application-list">
				<div className="content">
					<h2 className="title ftitle">Лента заданий</h2>
					<form className="search-line" onSubmit={handleSubmit}>
						<input
							type="text"
							placeholder="Поиск"
							value={search}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								setSearch(event.target.value)
							}}
						/>
						<button>
							<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="12.9999" cy="12.0556" r="11.3056" stroke="white" stroke-width="1.5" />
								<path d="M5.90839 20.5654L0.944336 25.5294" stroke="white" stroke-width="1.5" />
							</svg>
						</button>
					</form>

					{integrations.applications.length ? (
						integrations.applications.map((item, index) => {
							return (
								<div className="myprogramms__wrapper">
									<div className="row">
										<div className="col-md-4">
											<h3 className="name">{item.title}</h3>
											<div className="myprogramms__logo">
												<div
													className="myprogramms__logo-img"
													style={{
														backgroundImage: `url(${host.slice(0, -1)}${item.productImage})`,
														cursor: "pointer",
													}}
													onClick={() => {
														setShowPopUpImage(true)
														setPopUpURL(`${host.slice(0, -1)}${item.productImage}`)
													}}
												></div>
												<div className="myprogramms__logo-content">
													<div className="grid">
														<div>
															<div>{item.productTitle}</div>
															{item.price > 0 ? (
																<p
																	style={{
																		backgroundColor: "#f8f4ff",
																		display: "inline-block",
																		padding: "5px 14px",
																		borderRadius: 5,
																		color: "black",
																		fontSize: 14,
																		fontWeight: "500",
																	}}
																>
																	Цена: {item.price} руб.
																</p>
															) : (
																""
															)}
														</div>
													</div>
												</div>
											</div>
											<Link
												target="_blank"
												to={item.productLink}
												onClick={() => {
													ym("reachGoal", "urlclik")
													ReactGA.event({
														category: "urlclik",
														action: "click",
													})
												}}
												className="myprogramms__more"
											>
												Ссылка на товар
											</Link>
										</div>
										<div className="col-md-8 seller-info">
											<h3 className="myprogramms__subtitle" style={{ marginTop: 10 }}></h3>
											<div className="myprogramms__logo">
												<div
													className="myprogramms__logo-img"
													style={{
														backgroundImage: `url(${host.slice(0, -1)}${item.seller.image})`,
													}}
												></div>
												<div className="myprogramms__logo-content">
													<div className="grid">
														<div>
															<h4>{item.seller.name}</h4>
															<p>{item.seller.miniDesc}</p>
															<p
																style={{ minHeight: "38px", lineHeight: "1.5" }}
																className="item-desc"
															>
																<b>Формат рекламы: </b>
																{item.format1 ? "рекламный пост, " : ""}
																{item.format2 ? "выкуп на маркетплейсе, " : ""}
																{item.format3 ? "отзыв на маркетплейсе, " : ""}
																{item.format4 ? "раздача товаров подписчикам " : ""}
																<br />
																{item.miniDesc}
															</p>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div
													className="col-12"
													style={{
														marginTop: 5,
														display: "flex",
														height: "fit-content",
														width: "100%",
														alignItems: "center",
													}}
												>
													<Link
														to={`/integration/${item.pk}/`}
														onClick={() => {
															ym("reachGoal", "podrobnee")
															ReactGA.event({
																category: "podrobnee",
																action: "click",
															})
														}}
														className="myprogramms__more"
														style={{
															marginTop: 5,
															backgroundColor: "#3A0CA3",
															width: "auto",
															display: "inline-block",
															fontSize: "15px",
														}}
													>
														Подробнее
													</Link>
													<button
														className={classNames(
															"myprogramms__more",
															{
																[styles.wait]:
																	(item.applications as JobsApplications)?.status ===
																	"w8_seller",
																[styles.canceled]:
																	(item.applications as JobsApplications)?.status ===
																	"cancelled_seller",
															},
															[styles.button],
														)}
														disabled={
															!!statuses[(item.applications as JobsApplications)?.status]
														}
														onClick={() => {
															ym("reachGoal", "podatclik")
															ReactGA.event({
																category: "podatclik",
																action: "click",
															})

															if (!loadBtn) {
																setLoadBtn(true)
																item.SwitchStaus(auth.user.userToken, "application")
																	.then(function (response) {
																		setPopupType(response.msg)
																		setPopupError(response.error)
																		if (!response.error) {
																			integrations.UpdateIntegration(item.pk, index)
																		}
																	})
																	.catch(function (error) {
																		alert("error")
																		setLoadBtn(false)
																	})
															}
														}}
													>
														{(item.applications as JobsApplications)?.status === "w8_seller"
															? "Заявка подана"
															: "Подать заявку"}
													</button>

													<div className={styles.text}>
														{(item.applications as JobsApplications)?.status === "w8_seller" &&
															`Статус: ${
																statuses[(item.applications as JobsApplications)?.status]
															}`}
														{(item.applications as JobsApplications)?.status ===
															"cancelled_seller" &&
															`Статус: ${
																statuses[(item.applications as JobsApplications)?.status]
															}`}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						})
					) : (
						<div>
							<h3>Список пуст</h3>
						</div>
					)}

					<Navigation
						countPage={integrations.countPage}
						targetPage={integrations.targetPage}
						clickIvent={clickIvent}
					/>
				</div>
				{popupType !== "" ? (
					<div className="popup-msg">
						<div className="popup-msg__body">
							<h3>{popupType}</h3>
							<div
								className="popup-msg__link"
								onClick={() => {
									setPopupType("")
									setLoadBtn(false)
								}}
							>
								Продолжить
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</section>

			{showPopUpImage ? <PopupImage setShowPopUpImage={setShowPopUpImage} showPopUpURL={showPopUpURL} /> : ""}
		</>
	)
}
