import React, { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import ym from "react-yandex-metrika"

export const RegistrationINNPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [load2, setLoad2] = useState(true)

	const [step, setStep] = useState(0)
	const [pk, setPk] = useState(0)

	const [popupType, setPopupType] = useState("")
	const [popupError, setPopupError] = useState(true)
	const [form, setForm] = useState({
		name: "",
		email: "",
		phone: "",
		pass: "",
		cPass: "",
		userType: "seller",
		regLink: "",
		tglogin: "",
	})

	const [formLegal, setFormLegal] = useState({
		LegalFIO: "",
		LegalINN: "",
		LagalConsultation: false,
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (load) {
			setLoad(false)
			auth.user
				.register(
					form.name,
					form.email.toLowerCase(),
					form.phone,
					form.pass,
					form.cPass,
					form.userType,
					form.regLink,
					form.tglogin,
					//form.LegalFIO,
					//form.LegalINN,
					//form.LagalConsultation
				)
				.then(function (response) {
					ym("reachGoal", "registration_successfully")
					if (form.userType === "bloger" && !response.error) {
						setPk(response.pk)
						setStep(1)
						//setPopupType(response.msg)
						//setPopupError(true)
					} else {
						setPopupType(response.msg)
						setPopupError(response.error)
					}
				})
				.catch(function (error) {
					alert("Ошибка")
				})
		}
	}

	const changeHandlerLegal = (event: ChangeEvent<HTMLInputElement>) => {
		setFormLegal({ ...formLegal, [event.target.name]: event.target.value })
	}

	const handleSubmitLegal = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (load2) {
			setLoad2(false)
			auth.user
				.setLegal(formLegal.LegalFIO, formLegal.LegalINN, formLegal.LagalConsultation, pk)
				.then(function (response) {
					ym("reachGoal", "forma2")
					setPopupType(
						"Аккаунт успешно создан. На указанный номер телефона отправлено смс для активации аккаунта.",
					)
					setPopupError(false)
				})
				.catch(function (error) {
					alert("Ошибка")
				})
		}
	}

	return (
		<>
			<section className="form-item">
				<div className="content">
					<div className="grid">
						<div className="form-item__wrapper row">
							<div className="col-md-6">
								<div className="grid">
									<div>
										<h1>Создать аккаунт</h1>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								{step === 0 ? (
									<form onSubmit={handleSubmit}>
										<label>
											<input
												type="text"
												placeholder="Имя"
												required
												name="name"
												value={form.name}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<input
												type="email"
												style={{ textTransform: "lowercase" }}
												placeholder="E-mail"
												required
												name="email"
												value={form.email}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<input
												type="text"
												minLength={10}
												maxLength={20}
												placeholder="Номер телефона"
												required
												name="phone"
												value={form.phone}
												onChange={changeHandler}
											/>
										</label>

										<label>
											<input
												type="text"
												placeholder="Ник в telegram"
												required
												name="tglogin"
												value={form.tglogin}
												onChange={changeHandler}
											/>
										</label>

										<label>
											<span
												style={{
													textAlign: "left",
													fontSize: "12px",
													lineHeight: "1.25",
													marginTop: "-5px",
												}}
											>
												Ваш пароль должен содержать не менее восьми (8) символов в том числе: по
												крайней мере один (1) буквы в верхнем регистре
											</span>
											<input
												type="password"
												placeholder="Пароль"
												required
												name="pass"
												value={form.pass}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<input
												type="password"
												placeholder="Подтвердите пароль"
												required
												name="cPass"
												value={form.cPass}
												onChange={changeHandler}
											/>
										</label>
										<label>
											<select
												onChange={(e) => {
													setForm({ ...form, userType: e.target.value })
												}}
											>
												<option value="seller">Рекламодатель</option>
												<option value="bloger">Блогер</option>
											</select>
										</label>
										{form.userType === "bloger" ? (
											<label>
												<input
													type="text"
													placeholder="Ссылка на соцсеть"
													required
													name="regLink"
													value={form.regLink}
													onChange={changeHandler}
												/>
											</label>
										) : (
											""
										)}

										<button onClick={() => {}}>Зарегистрироваться</button>
										<p className="form__new-account reg-text">
											*Отправляя данные, вы соглашаетесь с{" "}
											<Link to={"/privacy/"} target="_blank">
												политикой конфиденциальности
											</Link>
										</p>
									</form>
								) : (
									<form onSubmit={handleSubmitLegal}>
										<div
											style={{
												cursor: "pointer",
												marginTop: "-15px",
												marginBottom: "35px",
												color: "white",
												fontSize: 16,
											}}
											onClick={() => {
												setStep(0)
											}}
										>
											{"<"} Назад
										</div>

										<h3
											style={{
												color: "white",
												marginTop: 0,
												marginBottom: 35,
											}}
										>
											Введите свои данные самозанятого или ИП
										</h3>
										<label>
											<input
												type="text"
												placeholder="ФИО"
												required={formLegal.LagalConsultation ? false : true}
												maxLength={255}
												name="LegalFIO"
												value={formLegal.LegalFIO}
												onChange={changeHandlerLegal}
											/>
										</label>
										<label>
											<input
												type="text"
												placeholder="ИНН"
												maxLength={255}
												name="LegalINN"
												value={formLegal.LegalINN}
												onChange={changeHandlerLegal}
											/>
										</label>
										<label>
											<input
												type="checkbox"
												checked={formLegal.LagalConsultation}
												onChange={() => {
													setFormLegal({
														...formLegal,
														LagalConsultation: !formLegal.LagalConsultation,
													})
												}}
											/>{" "}
											У меня нет самозанятости или ИП, но я хочу их оформить
										</label>

										<div style={{ marginTop: 20 }}>
											<label>
												<button onClick={() => {}}>Зарегистрироваться</button>
												<p className="form__new-account reg-text">
													*Отправляя данные, вы соглашаетесь с{" "}
													<Link to={"/privacy/"} target="_blank">
														политикой конфиденциальности
													</Link>
												</p>
											</label>
										</div>
									</form>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						{popupError ? (
							<div
								className="popup-msg__link"
								onClick={() => {
									setLoad(true)
									setPopupType("")
								}}
							>
								Продолжить
							</div>
						) : (
							<Link to="/login/" className="popup-msg__link">
								Войти
							</Link>
						)}
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
