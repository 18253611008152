
export const UserAgreementPage = () => {
	return (
		<section className="privacy">
			<div className="content">
				<h1 className="title">1. О пользовательском соглашении</h1>
				<p>
					<b>1.1.</b> Настоящее Пользовательское соглашение (далее — Соглашение) является офертой и регулирует
					отношения по использованию платформы «Blogerito» (далее – Платформа) между пользователем Платформы (далее
					– Пользователь) и ИП Матвеевым М.М. (далее - Правообладатель).
				</p>
				<p>
					<b>1.2.</b> Платформа представляет собой сервисно-информационную площадку предоставляющую Пользователям
					информацию о Блогерах и возможность заключить с ними соглашение о размещении информации.
				</p>
				<p>
					<b>1.3.</b> Пользователем может быть физическое лицо, индивидуальный предприниматель, юридическое лицо.{" "}
				</p>
				<p>
					<b>1.4.</b> Если Пользователем выступает юридическое лицо, то от его имени использовать Платформу могут
					физические лица, уполномоченные на это Пользователем-юридическим лицом.
				</p>
				<p>
					Пользователь-юридическое лицо уполномочивает физическое лицо использовать от его имени Платформу путем
					предоставления такому физическому лицу данных от аккаунта (адрес электронной почты и пароль)
					Пользователя-юридического лица.
				</p>
				<p>
					Физические лица, использующие Платформу от имени Пользователя-юридического лица, гарантируют, что
					уполномочены действовать от его имени.
				</p>
				<p>
					Правообладатель вправе в любое время запросить документы, подтверждающие полномочия представителя
					юридического лица.
				</p>

				<h3>2. Термины и определения</h3>
				<p>
					<b>2.1.</b> Платформа - совокупность объектов интеллектуальной собственности, включая, но не
					ограничиваясь: программы для ЭВМ, изображения, текстовые материалы, звуковые, графические, фото- и
					видеоматериалы, дизайн и внешний вид Платформы и иные материалы, доступные через Платформу, а также
					включая веб-сайт, расположенный по адресу:{" "}
					<a href="https://blogerito.ru/" rel="noreferrer" target="_blank">
						https://blogerito.ru/
					</a>
					.Платформа представляет собой информационную площадку для поиска Блогеров. Правообладатель оказывает
					Пользователю услуги предоставления доступа к Платформе, носящие информационный и
					информационно-технологический характер в соответствии с условиями Договора.
				</p>
				<p>
					<b>2.2.</b> Интеграция - совокупность основных условий размещения информации о товаре Пользователя на
					личной странице Блогера в информационной-телекоммуникационной сети «Интернет». Одной совершенной
					Интеграцией считается заключение договора с Блогером на размещение информации об одном товаре
					Пользователя.
				</p>
				<p>
					<b>2.3.</b> Аккаунт - личный кабинет Пользователя на Платформе, совокупность защищенных страниц,
					созданных в результате регистрации Пользователя, работая с которыми, Пользователь имеет возможность
					использовать функционал Платформы в объеме и на условиях, определяемых Договором и выбранным Тарифом.
				</p>
				<p>
					<b>2.4.</b> Социальные сети – любые социальные сети (VK, YouTube, Telegram, Дзен и другие), в которых у
					Блогера создан аккаунт и в которых Пользователь хочет разместить информацию о товаре.
				</p>
				<p>
					<b>2.5.</b> Пользовательский контент - любые комментарии, текстовые или голосовые сообщения, фотографии,
					графические изображения, видео, звуки, и другие материалы, данные и информация, а также ссылки на них,
					загруженные, переданные, опубликованные или иным образом распространенные Пользователем другими
					пользователями и (или) Правообладателю во время использования Платформы.
				</p>

				<h3>3. Принятие Соглашения</h3>
				<p>
					<b>3.1.</b> Создание Пользователем Аккаунта путем регистрации является безусловным и безоговорочным
					принятием (акцептом) Пользователем условий настоящего Соглашения, а также условий Внутренних правил
					Платформы, размещенных в сети «Интернет» по адресу:
					<a href="https://blogerito.ru/rules/" rel="noreferrer" target="_blank">
						https://blogerito.ru/rules/
					</a>
				</p>
				<p>
					<b>3.2.</b> Пользователь обязуется соблюдать требования и положений Политики в отношении обработки
					персональных данных, расположенной по адресу:{" "}
					<a href="https://blogerito.ru/privacy/" rel="noreferrer" target="_blank">
						https://blogerito.ru/privacy/
					</a>
				</p>
				<p>
					<b>3.3.</b> Пользователю необходимо внимательно ознакомиться со всеми условиями Соглашения до создания
					Аккаунта и начала использования Платформы.
				</p>
				<p>
					<b>3.4.</b> Если Пользователь не соглашается с условиями Соглашения, он не может использовать Платформу.
				</p>
				<p>
					<b>3.5.</b> Созданием Аккаунта путем регистрации Пользователь подтверждает, что обладает необходимой
					правоспособностью и дееспособностью для принятия условий Соглашения, способен исполнять Соглашение и
					нести ответственность за нарушение его условий.
				</p>
				<p>
					<b>3.6.</b> Если Пользователь достиг возраста четырнадцати лет, но не достиг возраста восемнадцати лет,
					то использование Платформы допускается только с разрешения родителя или попечителя несовершеннолетнего.
				</p>
				<p>Использование платформы лицами, не достигшими возраста четырнадцати лет, не допускается.</p>
				<p>
					Если родитель или попечитель разрешает несовершеннолетнему использовать Платформу, то он соглашается с
					условиями Соглашения от его и от своего имени. В таком случае родитель или попечитель несет полную
					ответственность за любое использование Платформы несовершеннолетним и отвечает за его действия как за
					свои собственные.
				</p>
				<p>
					Правообладатель вправе в любой момент запрашивать документы, подтверждающие согласие родителя или
					попечителя несовершеннолетнего на совершение последним действий на платформе. До момента получения
					документов Правообладатель вправе приостановить (ограничить) доступ несовершеннолетнего к Платформе.
				</p>
				<p>
					<b>3.7.</b> Также Правообладатель имеет право направлять Пользователю уведомления о новых продуктах и
					услугах, специальных предложениях и различных событиях, информационные и рекламные материалы, в том числе
					посредством email- и sms-рассылки на адрес электронной почты и телефон, сообщений в системе Telegram.
					Пользователь всегда может отказаться от получения указанных уведомлений и материалов, направив
					Правообладателю письмо на адрес электронной почты info@blogerito.ru с пометкой
				</p>
				<p>«Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».</p>

				<h3>4. Аккаунт Пользователя</h3>

				<p>
					<strong>4.1.</strong> Для использования Платформы Пользователю необходимо создать Аккаунт путем
					регистрации.
				</p>

				<p>
					<strong>4.2.</strong> Регистрация может происходить двумя способами:
				</p>
				<p>
					<strong>4.2.1.</strong> Вручную с помощью сотрудников Правообладателя. В таком случае Пользователь
					направляет сотрудникам Правообладателя заявку на регистрацию путем отправки сообщения в социальной сети
					Telegram по ссылке, указанной на Платформе.
				</p>
				<p>Заявка Пользователя должна содержать наименование (имя), адрес электронной почты Пользователя.</p>
				<p>
					Заявка Блогера должна содержать фамилию, имя, отчество, ссылку на аккаунт в Социальных сетях, адрес
					электронной почты, телефон и данные статистики в Социальных сетях. Правообладатель вправе запросить у
					Пользователя предоставление дополнительной информации.
				</p>
				<p>
					В таком случае сотрудники Правообладателя высылают Пользователю пароль для доступа в созданный Аккаунт.
					Логином Пользователя является адрес электронной почты Пользователя.
				</p>

				<p>
					<strong>4.2.2.</strong> С использованием Платформы путем заполнения специальной формы регистрации. В
					таком случае Пользователь должен указать запрашиваемую Платформой информацию, а также создать пароль для
					Аккаунта с учетом требований Платформы. Логином Пользователя является адрес электронной почты
					Пользователя.
				</p>

				<p>
					<strong>4.3.</strong> Заявки на регистрацию Аккаунта Пользователя на Платформе проходят модерацию.
					Правообладатель вправе принять или отклонить регистрацию Аккаунта Пользователя в любое время по
					собственному усмотрению по любой причине.
				</p>

				<p>
					<strong>4.4.</strong> Вход в Аккаунт Пользователя осуществляется на Платформе посредством ввода логина и
					пароля Пользователя в соответствующую форму на Платформе. Юридически значимые действия, совершенные
					Пользователем через его Аккаунт, являются совершенными с его простой электронной подписью, где
					идентификатором и ключом электронной подписи являются его аутентификационные данные для входа в Аккаунт.
				</p>

				<p>
					<strong>4.5.</strong> Аккаунт может использоваться только Пользователем, не может быть передан или продан
					другому Пользователю и не может использоваться совместно с другими лицами. Аккаунт Пользователя -
					юридического лица может использоваться только уполномоченными на использование Платформы физическими
					лицами.
				</p>

				<p>
					<strong>4.6.</strong> В случае совместного использования Аккаунта или его передачи другим лицам в
					нарушение пункта 4.5 Соглашения Пользователь несет все риски и будет нести полную ответственность за
					поведение и действия других лиц, использующих его Аккаунт, в том числе за нарушения Соглашения.
				</p>

				<p>
					<strong>4.7.</strong> Пользователь обязан обеспечивать конфиденциальность информации о своем Аккаунте,
					обеспечивать защиту своего Аккаунта и устройства, с помощью которого он использует Платформу, от
					постороннего доступа и несет за это единоличную ответственность.
				</p>

				<p>
					<strong>4.8.</strong> Пользователь также несет единоличную ответственность за любые убытки, возникшие в
					результате раскрытия информации о его Аккаунте, получения доступа к Аккаунту Пользователя третьим лицом.
				</p>

				<p>
					<strong>4.9.</strong> Пользователь-юридическое лицо несет все риски и любую ответственность за то, каким
					образом от его имени будет использовать Платформу и Сайт уполномоченное на то Пользователем-юридическим
					лицом физическое лицо.
				</p>

				<p>
					<strong>4.10.</strong> Правообладатель не несет никакой ответственности за убытки, которые могут
					возникнуть в результате неправомерного использования Аккаунта Пользователя, получения к нему
					несанкционированного доступа, раскрытия данных Пользователя и его Аккаунта. Пользователь самостоятельно
					несет ответственность за все действия и бездействия в рамках или с использованием Аккаунта и Платформы
					под именем Пользователя.
				</p>

				<p>
					<strong>4.11.</strong> Пользователь гарантирует, что вся предоставляемая им при регистрации и указанная в
					дальнейшем в Аккаунте личная информация о себе является верной, точной и актуальной. Пользователь
					обязуется обновлять эту информацию в своем Аккаунте сразу же после ее изменения, чтобы она всегда
					соответствовала указанным критериям, и несет все риски несоответствия информации действительности.
				</p>

				<p>
					<strong>4.12.</strong> Правообладатель вправе приостановить доступ к Аккаунту в случае обнаружения его
					взлома. Если Пользователь считает, что его доступ к Аккаунту был приостановлен по ошибке, он может
					обратиться в службу поддержки Правообладателя.
				</p>

				<p>
					<strong>4.13.</strong> Пользователь обязуется:
				</p>
				<ul>
					<li>
						немедленно уведомлять Правообладателя о любом неразрешенном Пользователем использовании его пароля,
						Аккаунта или о любом другом нарушении безопасности Аккаунта.
					</li>
					<li>
						незамедлительно сменить пароль от своего Аккаунта в случае, если пароль был утерян Пользователем или
						стал известен третьим лицам помимо воли Пользователя.
					</li>
				</ul>

				<p>
					<strong>4.14.</strong> Блогер подтверждает своё согласие с тем, что Правообладатель имеет право размещать
					в своих собственных информационных материалах сведения о том, что Блогер зарегистрирован и имеет Аккаунт
					на Платформе и пользуется её Услугами. Такая информация не является использованием Материалов в
					коммерческих целях Правообладателя и размещается исключительно для информирования пользователей Платформы
					об Услугах Платформы, порядке её использования, её технических и иных характеристиках. Блогер не вправе
					требовать какой-либо оплаты или возмещения от Правообладателя за использование его Материалов в
					информационных материалах Правообладателя.
				</p>

				<p>
					<strong>4.15.</strong> Блогер предоставляет право Правообладателю на использование своего (и/или иных
					лиц, непосредственно связанных/ отображаемых в результатах услуг) имени, псевдонима и/или изображения в
					соответствии со ст. 19 и ст. 152.1 ГК РФ, в том числе и в целях проведения Правообладателем рекламных
					кампаний. Данное согласие является безотзывным.
				</p>

				<p>
					<strong>4.16.</strong> Блогер предоставляет Правообладателю согласие на использование материалов,
					контента, а также объектов авторского права (дизайны, технические графики, фотографии, рисунки,
					иллюстрации, программное обеспечение, произведения искусства, видео, музыка, звуки, имена, слова,
					названия, фразы, логотипы, знаки и иные объекты), размещенных Блогером в Социальных сетях и на Платформе,
					в информационных и рекламных материалах Правообладателя, направленных на привлечение внимания к услугам
					Правообладателя, а также на информирование пользователей Платформы об услугах Платформы, порядке ее
					использования, технических и иных характеристиках. Использование вышеуказанных объектов авторского права
					включает в себя: воспроизведение, распространение, публичный показ, доведение до всеобщего сведения,
					сообщение по кабелю, переработку. Разрешение предоставляется Блогером на весь период использования
					Блогером Платформы и 10 лет после прекращения использования. Права на вышеуказанные материалы
					предоставляются Блогером Правообладателю безвозмездно на территории всего мира.
				</p>

				<p>
					<strong>4.17.</strong> Правообладатель вправе запрашивать, а Пользователь обязан предоставлять по такому
					запросу документы и информацию, необходимые для определения Пользователя как стороны настоящего
					Соглашения и/или стороны, использующей Платформу, а также документы, подтверждающие достоверность
					представленных сведений и правомерность использования/размещения материалов.
				</p>

				<h3>5. Предмет Соглашения</h3>
				<p>
					<strong>5.1.</strong> После принятия Пользователем Соглашения Правообладатель предоставляет Пользователю
					право на доступ к Платформе и ее использование в пределах, установленных Соглашением, Тарифом и
					функционалом Платформы, на территории всего мира в течение срока действия Соглашения (далее -
					«Лицензия»).
				</p>
				<p>Функционал платформы включает в себя:</p>

				<p>
					<b>1)</b> регистрация Аккаунта Пользователя и создание личного кабинета Пользователя, информационная
					поддержка при регистрации Аккаунта Пользователя;
				</p>
				<p>
					<b>2)</b> создание Интеграций / участие в Интеграциях;
				</p>
				<p>
					<b>3)</b> предоставление доступа к базе Интеграций/ Пользователей; получение информации об условиях
					Интеграций /о Пользователях;
				</p>
				<p>
					<b>4)</b> коммуникация Пользователей в чате по вопросам Интеграции, заключение договора на размещение
					информации о товаре, возможность фиксации хода исполнения сторонами принятых на себя обязательств;
				</p>
				<p>
					<b>5)</b> доступ к видеоурокам, базе знаний на Платформе;
				</p>
				<p>
					<b>6)</b> размещение отзывов Пользователей, Пользовательского контента;
				</p>
				<p>
					<b>7)</b> информационная и техническая поддержка Пользователей.
				</p>

				<p>
					<strong>5.2.</strong> Лицензия на Платформу предоставляет Пользователю возможность запуска и
					использования Платформы в соответствии с ее назначением, Тарифом и функциональными возможностями.
				</p>

				<p>
					<strong>5.3.</strong> Использование Платформы, ее контента и любых иных элементов Платформы возможно
					только в рамках функционала, предлагаемого Платформой.
				</p>

				<p>
					<strong>5.4.</strong> Доступ к Платформе предоставляется удаленно через сеть «Интернет». Пользователь
					самостоятельно и за свой счет обеспечивает доступ к сети «Интернет».
				</p>

				<p>
					<strong>5.5.</strong> Лицензия является:
				</p>
				<p>
					<strong>5.5.1.</strong> для Пользователей - личной, возмездной или (безвозмездной), неисключительной,
					ограниченной, отзывной, выданной без права передачи и выдачи сублицензий.
				</p>
				<p>
					Возмездность или безвозмездность Лицензии Пользователя определяется вариантом Тарифа, выбранным
					Пользователем для использования Платформы.
				</p>

				<p>
					<strong>5.6.</strong> Если иное прямо не разрешено Соглашением или в письменной форме Правообладателем,
					Пользователю запрещается:
				</p>
				<ul>
					<li>
						изменять, модифицировать, переводить, реконструировать, копировать, выводить, дизассемблировать,
						декомпилировать, иным образом распоряжаться Платформой или любой ее частью, или любым ее контентом,
						или любым ее компонентом;
					</li>
					<li>
						создавать производные от Платформы или любой ее части, или любого ее контента, или любого ее
						компонента произведения (или иные объекты интеллектуальных прав) полностью или частично;
					</li>
					<li>удалять, скрывать или изменять имеющиеся на Платформе уведомления об интеллектуальных правах;</li>
					<li>
						использовать Платформу любым способом, который может помешать другим Пользователям в полной мере
						использовать Платформу, или который может нарушить функционирование Платформы любым способом;
					</li>
					<li>использовать Платформу любым способом, который нарушает Соглашение и применимое право.</li>
				</ul>

				<p>
					<strong>5.7.</strong> Правообладатель оставляет за собой право применять технические и (или)
					количественные ограничения на использование функционала Платформы. Ограничения функционала Платформы
					могут быть связаны с выбранным Пользователем Тарифом.
				</p>

				<p>
					<b>6. Интеллектуальная собственность</b>
				</p>
				<p>
					<b>6.1.</b> Правообладатель является правообладателем всех интеллектуальных прав, включая исключительные,
					на Платформу, весь ее контент, ее части, компоненты, за исключением случаев, прямо предусмотренных
					Соглашением.
				</p>
				<p>
					<b>6.2.</b> Интеллектуальные права на Платформу, весь ее контент, ее части, компоненты, предоставляемые
					по Соглашению, не отчуждаются и не продаются. Ничто в настоящем Соглашении не может рассматриваться как
					передача исключительных прав Правообладателя на Платформу, весь ее контент, ее части, компоненты.
				</p>
				<p>Правообладателя на Платформу, весь ее контент, ее части, компоненты.</p>
				<p>
					<b>6.3.</b> Правообладатель может иметь исключительные права на товарные знаки. Пользователь не имеет
					права использовать или демонстрировать товарные знаки Правообладателя, если иное прямо не предусмотрено
					Соглашением.
				</p>

				<h3>7. Платформа как информационно-сервисная площадка</h3>
				<p>
					<strong>7.1.</strong> Платформа представляет собой информационно-сервисную площадку для взаимодействия
					Пользователей между собой по вопросам размещения информации о товарах в Социальных сетях.
				</p>

				<p>
					<strong>7.2.</strong> Правообладатель оказывает Пользователям услуги предоставления доступа к Платформе,
					носящие сервисный, организационный, информационный, информационно-технологический характер.
				</p>

				<p>
					<strong>7.3.</strong> Пользователю запрещается, прямо или косвенно, подразумевать, заявлять, что
					Правообладатель поддерживает, спонсирует какие-либо действия, предложения, продукты, сервисы или услуги,
					осуществляемые, рекламируемые, продвигаемые или предлагаемые другими Пользователями на Платформе.
				</p>
				<p>
					Правообладатель имеет право в случае, если сочтет, что Пользователь произвел ложное впечатление или
					создал двусмысленность по этому вопросу, попросить или потребовать от Пользователя сделать публичное
					заявление о том, что Правообладатель не спонсирует и не поддерживает действия, предложения, продукты,
					сервисы или услуги Пользователя.
				</p>

				<p>
					<strong>7.4.</strong> Правообладатель, являясь владельцем Платформы как информационно-сервисной площадки,
					не несет ответственности за действия Пользователей между собой, включая договоры на размещение информации
					о товарах, заключены между Пользователями с помощью Платформы, и проведение оплаты по ним.
				</p>

				<p>
					<strong>7.5.</strong> Платформа придерживается политического нейтралитета. Создание Интеграций,
					содержанием которых является политическая тематика, не допускается.
				</p>

				<h3>Вознаграждение за предоставление доступа к Платформе.</h3>
				<p>
					<strong>8.1.</strong> Пользователь обязан выплачивать Правообладателю вознаграждение за предоставление
					доступа к Платформе и ее использование. Размер и иные условия такого вознаграждения, условия
					предоставления доступа к Платформе и ее использования определяются тарифом, выбранным Пользователем
					самостоятельно на Платформе (далее – Тариф). Правообладатель вправе изменять Тарифы в одностороннем
					порядке. Вознаграждение Правообладателя уплачивается Пользователем путем перечисления суммы
					вознаграждения единовременно при пополнении Баланса.
				</p>
				<p>Предоставление доступа к Платформе и ее использование для Блогеров является безвозмездным.</p>

				<p>
					<strong>8.2.</strong> Срок действия Тарифа указывается на Сайте. Не использованные в течение срока
					действия Тарифа Интеграции и другие действия, реализуемые с помощью функционала Платформы, не переносятся
					на следующий период, оплата за них не возвращается.
				</p>

				<p>
					<strong>8.3.</strong> Пользователь может согласовать с Правообладателем индивидуальные условия
					предоставления доступа к Платформе и ее использования, включая условия о размере вознаграждения.
				</p>

				<p>
					<strong>8.4.</strong> Платежные реквизиты каждого Пользователя для перевода денежных средств указываются
					им самостоятельно в Аккаунте. Указывая платежную информацию, Пользователь подтверждает и гарантирует
					указание им достоверной и полной информации.
				</p>

				<p>
					<strong>8.5.</strong> Пополнение Баланса Пользователя происходит путем перевода денежных средств на
					расчетный счет Правообладателя.
				</p>

				<h3>9. Правила использования Платформы</h3>
				<p>
					<strong>9.1.</strong> При использовании Платформы запрещены:
				</p>
				<ul>
					<li>любая деятельность, противоречащая праву Российской Федерации, и призывы к ней;</li>
					<li>
						грубость, брань, нецензурные слова и выражения, оскорбления, угрозы, причинение беспокойства или
						вреда, агрессия, давление, запугивание, дискредитация, дискриминация;
					</li>
					<li>обсуждение самоубийств или призывы ним;</li>
					<li>спам;</li>
					<li>обман других Пользователей, мошенничество;</li>
					<li>
						использование или распространение вирусов, поврежденных данных, вредоносных программ, троянов, ботов,
						шпионских программ, инструментов для извлечения данных, и других программ, которые способны нанести
						или наносят вред Платформе, Правообладателю, другим Пользователям и третьим лицам, нарушают работу
						Платформы, скрыто контролируют, собирают, извлекают, перемещают системы и данные, в том числе
						персональные;
					</li>
					<li>осуществление, организация или содействие DDoS-атакам на Платформу, Пользователей;</li>
					<li>доступ или попытка доступа к частям Платформы, закрытой для отдельных Пользователей;</li>
					<li>выдача себя за другое лицо.</li>
				</ul>

				<p>
					<strong>9.2.</strong> Нарушение указанных правил может быть расценено Правообладателем как нарушение
					Соглашения и может привести к ограничению доступа Пользователя к Платформе в соответствии с разделом 18
					Соглашения.
				</p>

				<h3>10. Гарантии и ответственность</h3>
				<p>
					<strong>10.1.</strong> Платформа предоставляется Пользователю на условиях «как есть» и «как доступно»,
					без каких-либо гарантий, явных или подразумеваемых.
				</p>

				<p>
					<strong>10.2.</strong> Правообладатель не гарантирует пригодность Платформы для конкретных целей,
					коммерческую пригодность, отсутствие ошибок и сбоев, исправление неполадок, отсутствие вирусов,
					законность использования на любых территориях за пределами Российской Федерации, совместимость Платформы
					с используемым Пользователем техническим оборудованием, бесперебойную работу на любых используемых
					Пользователем технических средствах.
				</p>

				<p>
					<strong>10.3.</strong> Правообладатель не гарантирует, что Платформа и ее контент будут доступны всегда,
					во всех странах и регионах и в любой момент времени.
				</p>

				<p>
					<strong>10.4.</strong> Правообладатель не несет ответственности за, включая, но не ограничиваясь:
				</p>
				<ul>
					<li>невозможность использования Платформы;</li>
					<li>любые действия и (или) бездействия третьих лиц;</li>
					<li>искажение, изменение, утрату контента, в том числе Пользовательского контента;</li>
					<li>
						ущерб, который может быть нанесен любым устройствам и носителям информации, программному обеспечению
						Пользователя в результате использования Платформы.
					</li>
				</ul>

				<p>
					<strong>10.5.</strong> Пользователь несет единоличную ответственность за определение и уплату налогов по
					своим договорам.
				</p>

				<p>
					<strong>10.6.</strong> Правообладатель не несет ответственности за какие-либо потери, убытки Пользователя
					на Платформе.
				</p>

				<p>
					<strong>10.7.</strong> В случае нарушения Пользователем условий Соглашения он обязан в полном объеме
					возместить все убытки, причиненные Правообладателю и другим Пользователям.
				</p>

				<p>
					<strong>10.8.</strong> Правообладатель принимает меры для предотвращения появления «накрученных»
					аккаунтов, а также недействительных или неправомерных предложений, однако не гарантирует максимальную
					эффективность таких мер и не несет ответственности при выявлении подобных аккаунтов и предложений.
				</p>

				<p>
					<strong>10.9.</strong> При выявлении Правообладателем факта доступа третьих лиц к Аккаунту / Платформе
					Пользователь обязан во внесудебном порядке по письменному требованию Правообладателя оплатить штраф в
					размере 100 000 (Ста тысяч) рублей за каждый случай несанкционированного предоставления доступа третьим
					лицам.
				</p>

				<p>
					<strong>10.10.</strong> Пользователь обязан воздерживаться от любых действий, которые нарушают права
					Правообладателя на результаты интеллектуальной деятельности, в частности, не копировать, не записывать,
					не воспроизводить, не распространять любые результаты интеллектуальной деятельности Правообладателя без
					его письменного разрешения. В случае нарушения Пользователем прав Правообладателя, он несет
					ответственность перед Правообладателем за причиненные фактом распространения материала убытки, включая
					упущенную выгоду, а также обязуется выплатить штраф в размере 500 000 (Пятьсот тысяч) рублей.
				</p>

				<p>
					<strong>10.11.</strong> Правообладатель не несет никакой ответственности за любые материалы, размещенные
					или представленные Пользователями.
				</p>

				<p>
					<strong>10.12.</strong> Пользователи самостоятельно несут ответственность за соответствие информационных
					материалов требованиям Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе» и законодательства об
					авторских правах. В случае, если Правообладатель вследствие несоблюдения Пользователем действующего
					законодательства РФ, понесет убытки, Пользователь обязуется компенсировать такие убытки в полном объеме,
					включая суммы наложенных штрафов и сопутствующие расходы на урегулирование.
				</p>

				<h3>11. Ссылки на сторонние сайты </h3>
				<p>
					<b>11.1.</b> Платформа может содержать ссылки на сторонние веб-сайты или ресурсы. Такие сайты и ресурсы
					могут собирать информацию или запрашивать у Пользователя персональные данные. Правообладатель не
					контролирует такие сайты и не несет ответственности за их контент, продукты, услуги, политику
					конфиденциальности, порядок сбора, использования и раскрытия собираемой ими информации.
				</p>
				<p>
					Пользователь принимает на себя все риски, связанные с использованием им в рамках Платформы любых
					сторонних веб-сайтов или ресурсов.
				</p>

				<h3>12. Конфиденциальность</h3>
				<p>
					<strong>12.1.</strong> Под конфиденциальной информацией понимается вся та информация, которую
					Правообладатель при передаче Пользователю обозначает таковой (устно, письменно или наглядно), включая
					коммерческую, техническую, финансовую, маркетинговую и любую иную информацию.
				</p>

				<p>
					<strong>12.2.</strong> Пользователь обязуется не разглашать конфиденциальную информацию Правообладателя
					третьим лицам без получения предварительного письменного согласия Правообладателя. Под разглашением
					конфиденциальной информации Пользователем в рамках Соглашения понимается действие или бездействие
					Пользователя, в результате которого конфиденциальная информация Правообладателя становится известной
					третьим лицам в отсутствие согласия Правообладателя на это. При этом форма разглашения Пользователем
					конфиденциальной информации третьим лицам (устная, письменная, с использованием технических средств и
					другие) не имеет значения.
				</p>

				<p>
					<strong>12.3.</strong> Пользователь должен тщательно хранить полученную от Правообладателя
					конфиденциальную информацию так же, как хранит собственную конфиденциальную информацию.
				</p>

				<p>
					<strong>12.4.</strong> Пользователь в течение срока действия Соглашения, а также в течение двух лет по
					окончании срока его действия между сторонами, обязуется обеспечить сохранность конфиденциальной
					информации Правообладателя.
				</p>

				<p>
					<strong>12.5.</strong> Пользователь обязан незамедлительно сообщить Правообладателю о допущенном им либо
					ставшем ему известным факте разглашения или угрозы разглашения, незаконном получении или незаконном
					использовании конфиденциальной информации Правообладателя третьими лицами.
				</p>

				<p>
					<strong>12.6.</strong> Не является нарушением конфиденциальности Правообладателя предоставление
					конфиденциальной информации Пользователем по законному требованию правоохранительных и иных
					уполномоченных государственных органов и должностных лиц в случаях и в порядке, предусмотренных
					применимым правом.
				</p>

				<p>
					<strong>12.7.</strong> В случае раскрытия конфиденциальной информации указанным органам и (или) лицам
					Пользователь письменно уведомляет Правообладателя о факте предоставления такой информации, ее содержании
					и органе, которому предоставлена конфиденциальная информация, не позднее двух рабочих дней с момента
					раскрытия конфиденциальной информации.
				</p>

				<h3>13. Персональные данные</h3>
				<p>
					<strong>13.1.</strong> Правообладатель выполняет обработку персональных данных Пользователя в целях
					исполнения Соглашения согласно требованиям, установленным Федеральным законом от 27.07.2006 № 152-ФЗ «О
					персональных данных».
				</p>

				<p>
					<strong>13.2.</strong> Порядок обработки и защита персональных данных определяется Политикой обработки
					персональных данных и их конфиденциальности, расположенной по адресу:{" "}
					<a href="https://blogerito.ru/privacy/" rel="noreferrer" target="_blank">
						https://blogerito.ru/privacy/
					</a>
					.
				</p>

				<h3>14. Урегулирование споро</h3>
				<p>
					<strong>14.1.</strong> Все споры или разногласия, возникающие между Пользователем и Правообладателем в
					связи с исполнением Соглашения, разрешаются ими путем переговоров.
				</p>

				<p>
					<strong>14.2.</strong> При недостижении Пользователем и Правообладателем соглашения споры и разногласия
					подлежат разрешению в претензионном порядке. Претензия от Правообладателя направляется по адресу
					электронной почты Пользователя, указанной в Аккаунте Пользователя. Претензия от Пользователя направляется
					по следующему адресу электронной почты: <a href="mailto:info@blogerito.ru">info@blogerito.ru</a>.
				</p>

				<p>
					<strong>14.3.</strong> Срок ответа на претензию каждой из сторон составляет 14 календарный дней с момента
					ее получения.
				</p>

				<p>
					<strong>14.4.</strong> При невозможности прийти к соглашению спор подлежит рассмотрению и разрешению в
					суде по адресу Правообладателя.
				</p>

				<h3>15. Изменение Соглашения</h3>
				<p>
					<strong>15.1.</strong> Правообладатель вправе в любой момент вносить изменения в Соглашение по своему
					усмотрению.
				</p>

				<p>
					<strong>15.2.</strong> Изменения к Соглашению вступают в силу и становятся обязательными для Пользователя
					с момента размещения Соглашения в новой редакции на Платформе и не требуют какого-либо предварительного
					уведомления, одобрения, согласия Пользователя. В связи с этим Пользователь обязуется периодически следить
					за обновлениями Соглашения на Платформе.
				</p>

				<p>
					<strong>15.3.</strong> Пользователь имеет право не принимать изменения Соглашения. Если Пользователь не
					согласен с изменениями, он может расторгнуть Соглашение, удалив свой Аккаунт на Платформе. Продолжая
					использовать Платформу после вступления изменений в силу, Пользователь подтверждает свое согласие с этими
					изменениями.
				</p>

				<h3>16. Расторжение Соглашения</h3>
				<p>
					<strong>16.1.</strong> Соглашение является бессрочным, в силу чего любая из сторон Соглашения может в
					любой момент расторгнуть Соглашение в одностороннем порядке без возмещения каких-либо убытков другой
					стороне. Пользователь вправе в любое время удалить Аккаунт на Платформе и тем самым расторгнуть
					Соглашение.
				</p>

				<p>
					<strong>16.2.</strong> В случае нарушения Пользователем Соглашения Правообладатель вправе, в зависимости
					от серьезности нарушения, принять отдельные или все указанные ниже меры:
				</p>
				<ul>
					<li>на время заблокировать доступ к Аккаунту Пользователя;</li>
					<li>навсегда заблокировать Аккаунт Пользователя и тем самым расторгнуть Соглашение с Пользователем.</li>
				</ul>

				<p>
					<strong>16.3.</strong> Если нарушение Соглашения допущено Пользователем впервые, и оно не является
					серьезным, если его можно устранить, Правообладатель может уведомить Пользователя заранее в письменной
					форме о предстоящей блокировке Аккаунта за четырнадцать календарных дней, чтобы дать Пользователю
					возможность устранить нарушение в течение этого времени.
				</p>

				<p>
					<strong>16.4.</strong> Если Пользователь считает, что Правообладатель заблокировал Аккаунт по ошибке, он
					может обратиться в службу технической поддержки.
				</p>

				<p>
					<strong>16.5.</strong> Правообладатель не несет никакой ответственности за убытки, которые могут
					возникнуть у Пользователя при расторжении Соглашения.
				</p>

				<p>
					<strong>16.6.</strong> При расторжении Соглашения Пользователь имеет право на вывод денежных средств,
					находящихся на Балансе на Платформе, с Платформы за исключением случаев, предусмотренных Соглашением.
				</p>

				<h3>17. Прочие условия</h3>
				<p>
					<strong>17.1.</strong> К Соглашению и правоотношениям Пользователя и Правообладателя применяется право
					Российской Федерации.
				</p>

				<p>
					<strong>17.2.</strong> Пользователь принимает и соглашается с тем, что он использует Платформу в
					собственных коммерческих целях, и на использование Платформы не распространяется действие Закона РФ от
					07.02.1992 № 2300-1 «О защите прав потребителей». Пользователь не является «потребителем» в рамках
					указанного Закона, так как использует Платформу для получения коммерческой выгоды.
				</p>

				<p>
					<strong>17.3.</strong> Если какая-то часть Соглашения будет признана недействительной или незаключенной,
					другие части Соглашения продолжат действовать.
				</p>

				<p>
					<strong>17.4.</strong> Переписка, обмен копиями документов и иными сведениями по электронной почте,
					посредством мессенджеров и иных систем мгновенного обмена сообщениями признается юридически значимым,
					приравнивается к обмену оригиналами, является аналогом простой электронной подписи и не подлежит
					разглашению.
				</p>

				<p>
					<strong>17.5.</strong> Соглашение заменяет собой любые и все предыдущие устные или письменные
					договоренности или соглашения между Пользователем и Правообладателем в отношении Платформы.
				</p>

				<p>
					<strong>17.6.</strong> Правообладатель может публиковать разъяснения, рекомендации, правила, иные
					документы по поводу использования Платформы. В случае возникновения расхождений между Соглашением и
					такими документами преимущественную силу имеет Соглашение.
				</p>

				<p>
					<strong>17.7.</strong> Правообладатель всегда рад общению с Пользователями, а также получению обратной
					связи и предложений о Платформе. Направлять отзывы и предложения Пользователи могут по электронной почте:
					info@blogerito.ru.
				</p>

				<p>
					<strong>17.8.</strong> Пользователи могут обратиться к Правообладателю за технической и информационной
					поддержкой по адресу службы поддержки: info@blogerito.ru.
				</p>

				<p>
					<b>Реквизиты Правообладателя:</b>
					<br />
					Индивидуальный предприниматель Матвеев Михаил Михайлович
					<br />
					ИНН 772875163601
					<br />
					ОГРНИП: 323774600399651
					<br />
					Юридический адрес: 107140, РОССИЯ, г МОСКВА, ул КРАСНОПРУДНАЯ, ДОМ 22-24
				</p>
			</div>
		</section>
	)
}
