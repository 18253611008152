import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { ApplicationList, host } from "../../../classes"
import { Loader } from "../../../component/Loader"
import { Navigation } from "../../../component/Navigation"

export const BlogerStoryPage = (props: any) => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [application] = useState(new ApplicationList())

	useEffect(() => {
		application
			.Load(auth.user.userToken, "story")
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	const clickIvent = (target: number) => {
		application.targetPage = target
		setLoad(true)
		application
			.Load(auth.user.userToken, "story")
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	if (load) {
		return <Loader />
	}

	return (
		<section className="myprogramms">
			<div className="content">
				<h2 className="title ftitle">История выполненных заданий</h2>
				<form>
					<select
						onChange={(e) => {
							if (e.target.value === "story") {
								window.location.replace("/story/")
							} else {
								window.location.replace("/account/")
							}
						}}
					>
						<option value={"active"}>Активные проекты</option>
						<option value={"story"} selected={true}>
							Завершенные проекты
						</option>
					</select>
				</form>
				{application.applications.length ? (
					application.applications.map((item) => {
						return (
							<div className="project-list__item">
								<div
									className="logo"
									style={{ backgroundImage: `url(${host.slice(0, -1)}${item.integration.seller.image})` }}
								></div>
								<div className="project-list__content">
									<h3>
										{item.integration.title}
										<span>{item.date}</span>
									</h3>
									<p>{item.integration.miniDesc}</p>
								</div>
							</div>
						)
					})
				) : (
					<div>
						<h3>Список пуст</h3>
					</div>
				)}

				<Navigation countPage={application.countPage} targetPage={application.targetPage} clickIvent={clickIvent} />
			</div>
		</section>
	)
}
