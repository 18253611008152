import React, { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import axios from "axios"
import { host } from "../classes"

export const Form = (props: any) => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [popupType, setPopupType] = useState("")
	const [, setPopupError] = useState(true)
	const [form, setForm] = useState({
		name: "",
		email: "",
		phone: "",
		msg: "",
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (load) {
			setLoad(false)
			await axios
				.post(
					`${host}send-qw/`,
					{
						name: form.name,
						email: form.email,
						phone: form.phone,
						msg: form.msg,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: "Token " + auth.user.userToken,
						},
					},
				)
				.then((res) => {
					setPopupType(res.data.msg)
					setPopupError(res.data.error)
					setLoad(true)
					setForm({
						name: "",
						email: "",
						phone: "",
						msg: "",
					})
				})
				.catch(function (error) {
					alert("error")
					setLoad(true)
				})
		}
	}

	return (
		<>
			<section className="contact-form">
				<div className="content">
					<div className="contact-form__wrapper">
						<div className="row">
							<div className="col-md-6">
								<h3>Поддержка:</h3>
								<div className="contact-form__img"></div>
							</div>
							<div className="col-md-6">
								<p>Оставьте ваши данные и мы с вами свяжемся</p>
								<form className="row" onSubmit={handleSubmit}>
									<div className="col-md-6">
										<input
											type="text"
											placeholder="Имя"
											required
											name="name"
											value={form.name}
											onChange={changeHandler}
										/>
									</div>
									<div className="col-md-6">
										<input
											type="text"
											placeholder="Ваша почта"
											required
											name="email"
											value={form.email}
											onChange={changeHandler}
										/>
									</div>
									<div className="col-md-12">
										<input
											type="text"
											placeholder="Номер телефона"
											required
											name="phone"
											value={form.phone}
											onChange={changeHandler}
										/>
									</div>
									<div className="col-md-12">
										<textarea
											placeholder="Ваш комментарий"
											name="msg"
											required
											value={form.msg}
											onChange={(e) => setForm({ ...form, msg: e.target.value })}
										></textarea>
									</div>
									<div className="col-md-12">
										<button>Отправить</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setLoad(true)
								setPopupType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
