import React, { FC, useState } from "react"
import styles from "./EditAmount.module.css"

type EditAmountProps = {
	amount: number
	onChangeAmount: (amount: number) => void
}

export const EditAmount: FC<EditAmountProps> = ({ amount, onChangeAmount }) => {
	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [amountValue, setAmountValue] = useState<number>(amount)
	return (
		<div className={styles.wrapper}>
			{isEdit ? (
				<input
					max={3000}
					className={styles.input}
					type="number"
					value={amountValue}
					onChange={(event) => setAmountValue(+event.target.value > 3000 ? 3000 : +event.target.value)}
				/>
			) : (
				amountValue
			)}
			<button
				className={styles.button}
				onClick={() => {
					setIsEdit(!isEdit)
					if (isEdit) {
						onChangeAmount(amountValue)
					}
				}}
			>
				{isEdit ? "Сохранить" : "Изменить"}
			</button>
		</div>
	)
}
