import React, { ChangeEvent, useContext, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"

export const PasswordPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [popupType, setPopupType] = useState("")
	const [popupError, setPopupError] = useState(true)
	const [form, setForm] = useState({
		email: "",
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (load) {
			setLoad(false)
			auth.user
				.password(form.email)
				.then(function (response) {
					setPopupType(response.msg)
					setPopupError(response.error)
				})
				.catch(function (error) {
					alert("Ошибка")
				})
		}
	}

	return (
		<>
			<section className="form-item">
				<div className="content">
					<div className="form-item__wrapper row">
						<div className="col-md-6">
							<div className="grid">
								<div>
									<h1>Забыли пароль?</h1>
									<h2>Введите e-mail, и мы вышлем письмо для восстановления пароля</h2>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<form onSubmit={handleSubmit}>
								<label>
									<input
										type="email"
										placeholder="E-mail"
										required
										name="email"
										value={form.email}
										onChange={changeHandler}
									/>
								</label>
								<button>Восстановить пароль</button>
							</form>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						{popupError ? (
							<div
								className="popup-msg__link"
								onClick={() => {
									setLoad(true)
									setPopupType("")
								}}
							>
								Продолжить
							</div>
						) : (
							<Link to="/login/" className="popup-msg__link">
								Войти
							</Link>
						)}
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
