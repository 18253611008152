import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { PayLog, Seller } from "../classes"
import { Loader } from "../component/Loader"
import { Navigation } from "../component/Navigation"
import styles from "./PayStoryPage.module.css"
import { getOwnInfo } from "../api/getOwnInfo"
import { getIntegrationBalance } from "../api/getIntegrationBalanse"
import { changeIntegrationStatus, ChangeIntegrationStatusType } from "../api/changeIntegrationStatus"
import { exportBalanceOnExel } from "../api/exportBalanseOnExel"
import { EditAmount } from "../component/EditAmount/EditAmount"
import { changeIntegrationAmount } from "../api/changeIntegrationAmount"

export const PayStoryPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [payType, setPayType] = useState("30")
	const [payList] = useState(new PayLog())
	const [isOwnSeller, setIsOwnSeller] = useState(false)
	const [selfBalance, setSelfBalance] = useState<
		{
			id: number
			amount: number
			date: string
			status: "pending" | "completed"
			blogger_id: number
			integration_id: number
			title: string
			blogger_name: string
			seller_id: number
			seller_name: string
		}[]
	>([])

	const clickIvent = (target: number) => {
		payList.targetPage = target
		setLoad(true)
		payList
			.LoadCatalog(auth.user.userToken)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!load) {
			setLoad(true)
			if (auth.user.typeObject instanceof Seller)
				auth.user
					.typeObject!.NewPay(payType)
					.then(function (respons) {
						window.location.replace(respons.msg)
					})
					.catch(function (error) {
						alert("error")
					})
		}
	}

	useEffect(() => {
		payList
			.LoadCatalog(auth.user.userToken)
			.then(function (respons) {
				if (auth.user.typeObject instanceof Seller)
					auth.user
						.typeObject!.LoadScore()
						.then(function (respons) {
							setLoad(false)
						})
						.catch(function (error) {
							setLoad(false)
							alert("error")
						})
			})
			.catch(function (error) {
				setLoad(false)
				alert("error")
			})
		getOwnInfo().then((response) => {
			setIsOwnSeller(response)
			if (response) {
				getIntegrationBalance({
					seller_id: auth.user.pk,
				}).then((response) => setSelfBalance(response))
			}
		})
	}, [])

	if (load) {
		return <Loader />
	}
	const handleChangeBalance = (data: ChangeIntegrationStatusType) => {
		changeIntegrationStatus(data).then((response) => {
			if (response === 200) {
				getIntegrationBalance({ seller_id: auth.user.pk }).then((response) => setSelfBalance(response))
			}
		})
	}

	return (
		<section className="pay-list">
			<div className="content">
				{auth.user.typeObject instanceof Seller ? (
					<div className="user-score">
						<div className="row">
							<div className="col-md-8">
								<h3>Баланс аккаунта</h3>

								<p>
									<b>Подписка действует до:</b> {auth.user.typeObject.subscribeDate}
								</p>
								<p>
									<b>Осталось интеграций:</b> {auth.user.typeObject.integrationsCount}
								</p>
							</div>
							<div className="col-md-4">
								<div className="grid">
									<form onSubmit={handleSubmit} className="pay-form">
										<label>
											Количество интеграций в месяц
											<select
												onChange={(e) => {
													setPayType(e.target.value)
												}}
											>
												<option value={"30"}>30 интеграций (5000 руб)</option>
												<option value={"60"}>60 интеграций (8000 руб)</option>
												<option value={"100"}>100 интеграций (12000 руб)</option>
											</select>
										</label>
										<button>Пополнить</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{isOwnSeller && (
					<>
						<div className={styles.header}>
							<div style={{ fontSize: "32px", fontWeight: "600" }}>Баланс переводов блогерам</div>
							<div className={styles.buttons}>
								<button
									className="btn"
									style={{ background: "#207444" }}
									onClick={() => {
										exportBalanceOnExel(auth.user.pk)
									}}
								>
									Выгрузить в excel
								</button>
								<button
									style={{ width: "300px" }}
									onClick={() => handleChangeBalance({ status: "completed" })}
								>
									Все переведено
								</button>
							</div>
						</div>
						<table className={styles.table}>
							<thead>
								<tr className={styles.tableHeader}>
									<th>Название проекта</th>
									<th>Блогер</th>
									<th>Сумма</th>
									<th>Дата</th>
									<th>Статус</th>
								</tr>
							</thead>
							<tbody className={styles.tbody}>
								{selfBalance.map((itemBalance) => (
									<tr key={itemBalance.integration_id}>
										<td>
											{itemBalance.title}, {itemBalance.integration_id}
										</td>
										<td>
											{itemBalance.blogger_name}, {itemBalance.blogger_id}
										</td>
										<td>
											{itemBalance.status === "pending" ? (
												<EditAmount
													amount={itemBalance.amount}
													onChangeAmount={(amount) => {
														changeIntegrationAmount({ id: itemBalance.id, amount })
													}}
												/>
											) : (
												itemBalance.amount
											)}
										</td>
										<td>{itemBalance.date}</td>
										<td>
											<select
												value={itemBalance.status}
												onChange={(event) => {
													handleChangeBalance({
														id: itemBalance.id,
														status: event.target.value,
													})
												}}
											>
												<option value="pending">Ожидание оплаты</option>
												<option value="completed">Оплата проведена</option>
											</select>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				)}
				<h2 className="title ftitle">История денежных операций</h2>

				{payList.payList ? (
					payList.payList.length ? (
						payList.payList.map((item, index) => {
							return (
								<div key={index} className="pay-list__item">
									<b>Название: </b>
									{item.title}
									<br />
									<b>Дата: </b>
									{item.date}
									<br />
									<b>Сумма операции: </b>
									{item.count} руб
									<br />
								</div>
							)
						})
					) : (
						<div>
							<h3>Список пуст</h3>
						</div>
					)
				) : (
					<div>
						<h3>Список пуст</h3>
					</div>
				)}

				<Navigation countPage={payList.countPage} targetPage={payList.targetPage} clickIvent={clickIvent} />
			</div>
		</section>
	)
}
