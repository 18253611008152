import React, { ChangeEvent, useContext, useState, useEffect } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { city } from "../../../classes/lists"
import { Bloger } from "../../../classes"
import QRCode from "qrcode"
import { NotificationForm } from "../../../component/NotificationForm"
import { Loader } from "../../../component/Loader"

export const BlogerSettingPage = (props: any) => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [loadForm, setLoadForm] = useState(true)
	const [popupType, setPopupType] = useState("")
	const [popupError, setPopupError] = useState(true)
	const [qrImage, setQrImage] = useState("")

	useEffect(() => {
		auth.user
			.typeObject!.LoadData(auth.user)
			.then(function (response) {
				setLoad(false)

				if (auth.user.typeObject instanceof Bloger) {
					setNotifications({
						notificationsType: auth.user.typeObject.sendNotificationsType,
						notificationsEmail: auth.user.typeObject.notificationsMail,
					})
					console.log(notifications)

					setForm({
						name: auth.user.userName,
						bDate: auth.user.typeObject.dopInfo.bDate,
						city: auth.user.typeObject.dopInfo.city,
						minidesc: auth.user.typeObject.dopInfo.minidesc,
						category: auth.user.typeObject.dopInfo.category,
						phone: auth.user.typeObject.dopInfo.phone,
						youtube: auth.user.typeObject.dopInfo.youtube,
						vk: auth.user.typeObject.dopInfo.vk,
						instagram: auth.user.typeObject.dopInfo.instagram,
						telegram: auth.user.typeObject.dopInfo.telegram,
						rutube: auth.user.typeObject.dopInfo.rutube,
						doc: auth.user.typeObject.dopInfo.doc,
						desc: auth.user.typeObject.dopInfo.desc,
						postType1: auth.user.typeObject.dopInfo.postType1,
						postType2: auth.user.typeObject.dopInfo.postType2,
						postType3: auth.user.typeObject.dopInfo.postType3,
						postType4: auth.user.typeObject.dopInfo.postType4,
						postType5: auth.user.typeObject.dopInfo.postType5,
					})

					QRCode.toDataURL(`tg://resolve?domain=blogerito_bot&start=${auth.user.typeObject?.secretKey}`).then(
						(val) => {
							setQrImage(val)
						},
					)
				}
			})
			.catch(function (error) {
				alert("error")
			})
	}, [])

	const [form, setForm] = useState({
		name: "",
		bDate: "",
		city: "",
		minidesc: "",
		category: "",
		phone: "",
		youtube: "",
		vk: "",
		instagram: "",
		telegram: "",
		rutube: "",
		doc: "",
		desc: "",
		postType1: false,
		postType2: false,
		postType3: false,
		postType4: false,
		postType5: false,
	})

	const [notifications, setNotifications] = useState({
		notificationsType: "",
		notificationsEmail: "",
	})

	const [formPassword, setFormPassword] = useState({
		password: "",
		cPassword: "",
	})
	const changeHandlerForm = (event: ChangeEvent<HTMLInputElement>) => {
		setFormPassword({ ...formPassword, [event.target.name]: event.target.value })
	}

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	function handlerImage(e: any) {
		setForm({ ...form, doc: e.target.files[0] })
		console.log(e.target.files)
	}

	const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm) {
			setLoadForm(false)
			auth.user
				.UpdatePassword(formPassword.password, formPassword.cPassword)
				.then(function (response) {
					setPopupType(response.msg)
					setPopupError(response.error)
					setLoadForm(true)
				})
				.catch(function (error) {
					setLoadForm(true)
					alert("error")
				})
		}
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm) {
			setLoadForm(false)

			if (auth.user.typeObject instanceof Bloger) {
				auth.user.typeObject
					.SaveData(form)
					.then(function (response) {
						setPopupType(response.msg)
						setPopupError(response.error)
						setLoadForm(true)
					})
					.catch(function (error) {
						setLoadForm(true)
						alert("error")
					})
			}
		}
	}

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="bloger-info">
				<div className="content">
					<div className="row">
						<div className="col-md-3 order1">
							<div
								className="bloger-info__userpic"
								style={{ backgroundImage: "url('" + auth.user.image + "')" }}
							></div>
							<label className="dop-file">
								Изменить фото
								<input
									type="file"
									style={{ display: "none" }}
									accept="image/*"
									onChange={(e) => {
										if (loadForm) {
											setLoadForm(false)
											if (e.target.files) {
												auth.user
													.SaveUserpic(e.target.files[0])
													.then(function (response) {
														setPopupType("Фото обновлено")
														setPopupError(response.error)
														setLoadForm(true)
													})
													.catch(function (error) {
														alert("error")
														setLoadForm(true)
													})
											}
										}
									}}
								/>
							</label>
						</div>

						<div className="col-md-9 order2">
							<h2 className="title ftitle">Настройки аккаунта</h2>

							<form className="form-item__wrapper" onSubmit={handleSubmit}>
								<label>
									ФИО*
									<input
										type="text"
										maxLength={255}
										required
										name="name"
										value={form.name}
										onChange={changeHandler}
									/>
								</label>

								<label>
									Дата рождения*
									<input type="date" required name="bDate" value={form.bDate} onChange={changeHandler} />
								</label>

								<label>
									Город
									<select
										name="city"
										onChange={(e) => {
											setForm({ ...form, city: e.target.value })
										}}
									>
										{city.map((item) => {
											return <option selected={form.city === item}>{item}</option>
										})}
									</select>
								</label>

								<label>
									Краткий текст о вас*
									<textarea
										maxLength={80}
										required
										name="minidesc"
										value={form.minidesc}
										onChange={(e) => {
											setForm({ ...form, minidesc: e.target.value })
										}}
									/>
								</label>

								<label>
									Тематики блога*
									<input
										maxLength={255}
										type="text"
										required
										name="category"
										value={form.category}
										onChange={changeHandler}
									/>
								</label>

								<label>
									Номер телефона*
									<input
										maxLength={255}
										type="text"
										required
										name="phone"
										value={form.phone}
										onChange={changeHandler}
									/>
								</label>

								<div className="soc-input">
									<h3>
										Ссылка на соц.сеть*
										<br />
										<span>(Укажите минимум 1 ссылку)</span>
									</h3>

									<div className="row">
										<div className="col-md-6">
											<label>
												Ссылка Youtube
												<input
													maxLength={510}
													type="text"
													name="youtube"
													value={form.youtube}
													onChange={changeHandler}
												/>
											</label>
										</div>
										<div className="col-md-6">
											<label>
												Ссылка VK
												<input
													maxLength={510}
													type="text"
													name="vk"
													value={form.vk}
													onChange={changeHandler}
												/>
											</label>
										</div>
										<div className="col-md-6">
											<label>
												Ссылка Instagram
												<input
													maxLength={510}
													type="text"
													name="instagram"
													value={form.instagram}
													onChange={changeHandler}
												/>
											</label>
										</div>
										<div className="col-md-6">
											<label>
												Ссылка Telegram
												<input
													maxLength={510}
													type="text"
													name="telegram"
													value={form.telegram}
													onChange={changeHandler}
												/>
											</label>
										</div>
										<div className="col-md-12">
											<label>
												Ссылка Rutube, Дзен,Tenchat и др.
												<input
													maxLength={510}
													type="text"
													name="rutube"
													value={form.rutube}
													onChange={changeHandler}
												/>
											</label>
										</div>
									</div>
								</div>

								<label>
									Документ
									<input
										type="file"
										name="doc"
										onChange={handlerImage}
										accept="image/*"
										style={{ color: "black" }}
									/>
								</label>

								<label>
									Полный текст о вас*
									<textarea
										required
										name="desc"
										value={form.desc}
										onChange={(e) => {
											setForm({ ...form, desc: e.target.value })
										}}
									/>
								</label>

								<div className="soc-input">
									<h3>Формат рекламы*</h3>

									<label>
										<input
											type="checkbox"
											name="postType1"
											checked={form.postType1 ? true : false}
											onChange={(e) => {
												setForm({ ...form, [e.target.name]: e.target.checked })
											}}
										/>
										Рекламный пост
									</label>

									<label>
										<input
											type="checkbox"
											name="postType2"
											checked={form.postType2 ? true : false}
											onChange={(e) => {
												setForm({ ...form, [e.target.name]: e.target.checked })
											}}
										/>
										Выкуп на маркетплейсе
									</label>

									<label>
										<input
											type="checkbox"
											name="postType5"
											checked={form.postType5 ? true : false}
											onChange={(e) => {
												setForm({ ...form, [e.target.name]: e.target.checked })
											}}
										/>
										Видеоотзыв на маркетплейсе
									</label>

									<label>
										<input
											type="checkbox"
											name="postType3"
											checked={form.postType3 ? true : false}
											onChange={(e) => {
												setForm({ ...form, [e.target.name]: e.target.checked })
											}}
										/>
										Отзыв на маркетплейсе
									</label>

									<label>
										<input
											type="checkbox"
											name="postType4"
											checked={form.postType4 ? true : false}
											onChange={(e) => {
												setForm({ ...form, [e.target.name]: e.target.checked })
											}}
										/>
										Раздача товаров подписчикам
									</label>
								</div>

								<button>Обновить</button>
							</form>

							<NotificationForm
								notifications={notifications}
								setNotifications={setNotifications}
								qrImage={qrImage}
								loadForm={loadForm}
								setLoadForm={setLoadForm}
								popupType={popupType}
								setPopupType={setPopupType}
								popupError={popupError}
								setPopupError={setPopupError}
							/>

							<h2 className="title" style={{ marginTop: 42 }}>
								Изменить пароль
							</h2>
							<form className="form-item__wrapper" onSubmit={handleSubmitPassword}>
								<label>
									Пароль
									<input
										type="password"
										required
										name="password"
										value={formPassword.password}
										onChange={changeHandlerForm}
									/>
								</label>
								<label>
									Подтвердите пароль
									<input
										type="password"
										required
										name="cPassword"
										value={formPassword.cPassword}
										onChange={changeHandlerForm}
									/>
								</label>

								<button>Обновить</button>
							</form>
						</div>
					</div>
				</div>
			</section>

			{popupType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setPopupType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
