import React, { useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { useParams } from "react-router-dom"
import { host, SellerItem } from "../classes"
import confI1 from "../image/conf_i1.svg"
import confI2 from "../image/conf_i2.svg"
import howWorkD2 from "../image/how-work_d2.png"
import { Form } from "../component/Form"
import { Loader } from "../component/Loader"

export const SellerItemPage = () => {
	const auth = useContext(GlobalContext)
	const { id } = useParams()
	const [load, setLoad] = useState(true)
	const [catalog] = useState(new SellerItem(id ? id : "0"))
	const [showText, setShowText] = useState(false)
	const [reviewsType, setReviewsType] = useState("plus")

	useEffect(() => {
		catalog
			.Load(auth.user.userToken)
			.then(function (response) {
				catalog
					.LoadReviews(auth.user.userToken, reviewsType, "seller")
					.then(function (response) {
						setLoad(false)
						console.log(catalog.reviews)
					})
					.catch(function (error) {
						setLoad(false)
						alert("error")
					})
			})
			.catch(function (error) {
				setLoad(false)
				alert("error")
			})
	}, [])

	useEffect(() => {}, [reviewsType])

	if (load) {
		return <Loader />
	}

	return (
		<>
			<section className="bloger-info">
				<div className="content">
					<div className="row">
						<div className="col-md-3 order1">
							<div
								className="bloger-info__userpic"
								style={{ marginBottom: 20, backgroundImage: `url(${host}${catalog.sellerInfo?.image})` }}
							></div>

							<div
								className="sub-prog__rew"
								style={{
									borderTop: "1px solid rgba(0,0,0,0.1)",
									paddingTop: "15px",
									marginBottom: "25px",
								}}
							>
								<div>
									<p>Отзывы</p>
								</div>
								<div>
									<div className="sub-prog__proc">
										<div style={{ borderRight: "1.5px solid #3A0CA3" }}>
											{catalog.sellerInfo?.goodreview}+
										</div>
										<div>{catalog.sellerInfo?.badreview}-</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-9 order3">
							<div className="bloger-info__wrapper">
								<h2 className="bloger-info__title">
									{catalog.sellerInfo?.name}
									<span>{catalog.sellerInfo?.bDate}</span>
								</h2>
								<p className="bloger-info__minidesc">{catalog.sellerInfo?.minidesc}</p>
								<div className="row">
									<div className="col-md-12">
										{catalog.sellerInfo?.docConf === true ? (
											<p className="bloger-info__dop" style={{ backgroundImage: `url(${confI1})` }}>
												Документы подтверждены
											</p>
										) : (
											""
										)}
										{catalog.sellerInfo?.phoneConf === true ? (
											<p className="bloger-info__dop" style={{ backgroundImage: `url(${confI2})` }}>
												Телефон подтверждены
											</p>
										) : (
											""
										)}
									</div>
								</div>
							</div>

							<div className={`bloger-info__maintext ${!showText ? "shadow" : ""}`}>
								<p>{catalog.sellerInfo?.desc}</p>
							</div>
							{!showText ? (
								<button
									className="myprogramms__morebtnitem"
									onClick={() => {
										setShowText(true)
									}}
								>
									Показать целиком
								</button>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</section>

			<section className="bloger-page__review">
				<div className="how-works__dots" style={{ backgroundImage: `${howWorkD2}` }}>
					<div className="content">
						<h2>Отзывы:</h2>
						<select
							onChange={(e) => {
								setReviewsType(e.target.value)
								catalog.reviews = []
								catalog.reviews.shift()
								catalog.targetPage = 1
								setLoad(true)
								catalog
									.LoadReviews(auth.user.userToken, reviewsType, "seller")
									.then(function (response) {
										setLoad(false)
									})
									.catch(function (error) {
										alert("error")
									})
							}}
						>
							<option value={"plus"} selected={reviewsType === "plus" ? true : false}>
								Положительные
							</option>
							<option value={"min"} selected={reviewsType === "min" ? true : false}>
								Отрицательные
							</option>
						</select>
						{catalog.reviews
							? catalog.reviews.length
								? catalog.reviews.map((item, index) => {
										return (
											<div key={index} className="bloger-page__review-item">
												<div className="row">
													<div className="col-md-2">
														<div className="stars-line">
															{Number(item.estimation) >= 1 ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="17"
																	height="16"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"
																		fill="#F8C50F"
																	/>
																</svg>
															) : (
																""
															)}
															{Number(item.estimation) >= 2 ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="17"
																	height="16"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"
																		fill="#F8C50F"
																	/>
																</svg>
															) : (
																""
															)}
															{Number(item.estimation) >= 3 ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="17"
																	height="16"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"
																		fill="#F8C50F"
																	/>
																</svg>
															) : (
																""
															)}
															{Number(item.estimation) >= 4 ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="17"
																	height="16"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"
																		fill="#F8C50F"
																	/>
																</svg>
															) : (
																""
															)}
															{Number(item.estimation) >= 5 ? (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="17"
																	height="16"
																	viewBox="0 0 17 16"
																	fill="none"
																>
																	<path
																		d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z"
																		fill="#F8C50F"
																	/>
																</svg>
															) : (
																""
															)}
														</div>
														<div className="bloger-info__review-date">{item.date}</div>
													</div>
													<div className="col-md-10">
														<h3>
															Задание:
															<span>{item.integration.title}</span>
														</h3>
														<p>{item.text}</p>
													</div>
												</div>
											</div>
										)
								  })
								: ""
							: ""}
						{catalog.countPage > catalog.targetPage ? (
							<button
								className="myprogramms__morebtnitem"
								onClick={() => {
									catalog.targetPage += 1
									setLoad(true)
									catalog
										.LoadReviews(auth.user.userToken, reviewsType, "seller")
										.then(function (response) {
											setLoad(false)
										})
										.catch(function (error) {
											alert("error")
										})
								}}
							>
								Загрузить еще +
							</button>
						) : (
							""
						)}
					</div>
				</div>
			</section>

			<Form />
		</>
	)
}
