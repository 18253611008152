import React, { useContext, useEffect, useState } from "react"
import styles from "./BloggerBalance.module.css"
import { GlobalContext } from "../../context/GlobalContext"
import { getIntegrationBalance } from "../../api/getIntegrationBalanse"

export const BloggerBalance = () => {
	const auth = useContext(GlobalContext)
	const [balance, setBalance] = useState<
		{
			amount: number
			date: string
			status: "pending" | "completed"
			blogger_id: number
			integration_id: number
			title: string
			blogger_name: string
			seller_id: number
			seller_name: string
		}[]
	>([])

	useEffect(() => {
		getIntegrationBalance({ blogger_id: auth.user.pk }).then((response) => setBalance(response))
	}, [])

	return (
		<div className="content">
			<div>
				<h2>
					Оплата за все интеграции будет произведена единоразово на указанные вами данные карты, в ближайший
					платежный день
				</h2>
			</div>
			<h2 style={{ padding: "32px 0" }}>Ваш баланс:</h2>
			<div>
				{" "}
				Если здесь указана сумма, значит оплата от селлера вам уже зачислена, можете переводить интеграцию на
				следующий этап и заказывать товар.
			</div>
			<table className={styles.table}>
				<thead>
					<tr className={styles.tableHeader}>
						<th>Название проекта</th>
						<th>Селлер</th>
						<th>Сумма</th>
						<th>Дата</th>
						<th>Статус</th>
					</tr>
				</thead>
				<tbody className={styles.tbody}>
					{balance.map((itemBalance) => (
						<tr>
							<td>{itemBalance.title}</td>
							<td>{itemBalance.seller_name}</td>
							<td>{itemBalance.amount}</td>
							<td>{itemBalance.date}</td>
							<td>{itemBalance.status === "pending" ? "ожидание платежного дня" : "Оплата проведена"}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}
