import React, { ChangeEvent, useEffect, useState } from "react"
// import logo from "../image/logo.svg"
import axios from "axios"
// import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import frameHuman2 from "../image/hum_03.png"
import pic001 from "../image/pic_001.png"
import { host } from "../classes"

export const DashbordPopup = () => {
	// const auth = useContext(GlobalContext)
	const [showDashbord, setShowDashbord] = useState(false)
	const [showPopupForm, setShowPopupForm] = useState(false)
	const [load, setLoad] = useState(true)
	const [popupFType, setPopupFType] = useState("")

	const [popupStep, setPopupStep] = useState(0)

	const [form, setForm] = useState({
		email: "",
		name: "",
		msg: "",
	})

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setForm({ ...form, [event.target.name]: event.target.value })
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		if (load) {
			setLoad(false)
			await axios
				.post(`${host}contact-form/`, {
					email: form.email,
					name: form.name,
					msg: `Ник в telegram: ${form.msg}`,
				})
				.then((res) => {
					setShowPopupForm(false)
					setPopupFType(res.data.msg)
					setLoad(true)
					setForm({
						email: "",
						name: "",
						msg: "",
					})
				})
				.catch(function (error) {
					alert("error")
					setForm({
						email: "",
						name: "",
						msg: "",
					})
					setLoad(true)
				})
		}
	}

	useEffect(() => {
		//Проверка есть item или нет
		if (!localStorage.getItem("userDashbord")) {
			localStorage.setItem(
				"userDashbord",
				JSON.stringify({
					count: 1,
					complite: [],
				}),
			)
			setShowDashbord(true)
		} else {
			const userStorage = JSON.parse(localStorage.getItem("userDashbord") || "")
			if (userStorage.count < 3) {
				userStorage.count += 1
				localStorage.setItem(
					"userDashbord",
					JSON.stringify({
						count: userStorage.count,
						complite: userStorage.complite,
					}),
				)
				setShowDashbord(true)
			}
		}
	}, [])

	return (
		<>
			{popupFType !== "" ? (
				<div className="popup-msg">
					<div className="popup-msg__body">
						<h3>{popupFType}</h3>
						<div
							className="popup-msg__link"
							onClick={() => {
								setLoad(true)
								setPopupFType("")
							}}
						>
							Продолжить
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{showPopupForm ? (
				<div className="popup-form">
					<div>
						<div className="popup-form__close">
							<svg
								onClick={() => {
									setShowPopupForm(false)
								}}
								width="800px"
								height="800px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="Menu / Close_MD">
									<path
										id="Vector"
										d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
										stroke="#000000"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</g>
							</svg>
						</div>
						<form onSubmit={handleSubmit}>
							<label>
								<input
									type="email"
									placeholder="E-mail"
									required
									name="email"
									value={form.email}
									onChange={changeHandler}
								/>
							</label>
							<label>
								<input
									type="text"
									placeholder="Имя"
									required
									name="name"
									value={form.name}
									onChange={changeHandler}
								/>
							</label>
							<label>
								<input
									type="text"
									placeholder="Ник в telegram"
									required
									name="msg"
									value={form.msg}
									onChange={changeHandler}
								/>
							</label>
							<button>Отправить</button>
						</form>
					</div>
				</div>
			) : (
				""
			)}

			{showDashbord ? (
				<>
					{popupStep === 0 ? (
						<div className="popup-lesson">
							<div>
								<div className="popup-lesson__desc">
									<p>
										Привет! Меня зовут Михаил и я создатель сервиса Blogerito. Я очень рад, что вы решили
										попробовать наш сервис.
									</p>
									<p>
										Прежде всего, советую посмотреть видео-презентацию. Вы потратите немного времени, но
										зато узнаете основную информацию по корректной работе с нашим инструментом
										продвижения ваших карточек в топ.
									</p>
									<iframe
										title="Видео-презентация"
										src="https://rutube.ru/play/embed/8d30e2d07d59cee8324df0712e57ae76/"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									></iframe>
								</div>

								<div className="popup-lesson__btns">
									<div className="row">
										<div className="col-md-12">
											<button
												onClick={() => {
													setPopupStep(1)
												}}
											>
												Далее
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="dashbord-popup">
							<div>
								<h2>Добро пожаловать в Blogerito</h2>
								<div className="dashbord-popup__desc">
									<p>
										Чтобы быстрее научиться пользоваться нашим сервисом, рекомендую записаться на
										бесплатную консультацию. Вы сможете задать все появившиеся вопросы специалисту и
										сэкономите своё время.
										<br />
										<br />
										Так же я очень рекомендую пройти бесплатный обучающий онлайн-курс по методологии
										использования нашего сервиса. В рамках использования данной методологии, мы даём 100%
										гарантию вывода вашей карточки в ТОП по поисковому запросу. Курс состоит из 7 видео,
										каждое из которых длится не более 5 минут.
									</p>
								</div>
								<div className="row">
									<div className="col-md-6">
										<a
											onClick={() => {
												setShowDashbord(false)
												setShowPopupForm(true)
											}}
											className="dashbord-popup__btns"
										>
											<div className="" style={{ backgroundImage: `url('${frameHuman2}')` }}></div>
											<p>Записаться на консультацию со специалистом</p>
										</a>
									</div>
									<div className="col-md-6">
										<Link
											to={"/dashbord/"}
											onClick={() => {
												setShowDashbord(false)
											}}
											className="dashbord-popup__btns"
										>
											<div className="" style={{ backgroundImage: `url('${pic001}')` }}></div>
											<p>Пройти бесплатный онлайн-курс</p>
										</Link>
									</div>
								</div>
								<p
									className="dashbord-popup__close"
									onClick={() => {
										setShowDashbord(false)
									}}
								>
									Пропустить, я опытный пользователь
								</p>
							</div>
						</div>
					)}
				</>
			) : (
				""
			)}
		</>
	)
}
