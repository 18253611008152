import React, { useContext, useState } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import { SellerStoryPage } from "./SellerStoryPage/SellerStoryPage"
import { BlogerStoryPage } from "./BlogerStoryPage/BlogerStoryPage"

export const StoryPage = () => {
	const auth = useContext(GlobalContext)
	const [showVideo, setShowVideo] = useState(false)
	React.useEffect(() => {
		if (auth.user.userType === "bloger") {
			if (!localStorage.getItem("userVideo")) {
				localStorage.setItem(
					"userVideo",
					JSON.stringify({
						status: true,
					}),
				)
				setShowVideo(true)
			}
		}
	}, [])

	return (
		<>
			{auth.user.userType === "bloger" ? <BlogerStoryPage /> : <SellerStoryPage />}
			{showVideo ? (
				<div className="popup-video">
					<div className="popup-video__close">
						<svg
							width="40px"
							height="40px"
							viewBox="0 0 1024 1024"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								setShowVideo(false)
							}}
						>
							<path
								fill="#fff"
								d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"
							/>
						</svg>
					</div>
					<div>
						{auth.user.userType === "bloger" ? (
							<iframe
								title="Blogger video"
								width="560"
								height="315"
								src="https://rutube.ru/play/embed/9ca5fdd4b981de7db5ffdd86fc272daa/"
							></iframe>
						) : (
							<iframe
								title="Seller video"
								width="560"
								height="315"
								src="https://rutube.ru/play/embed/c0052973737b3ac604e91aa98918bcb7/"
							></iframe>
						)}
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
