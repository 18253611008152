import React, { useContext, useState, useEffect, useRef } from "react"
import axios from "axios"
import logo from "../image/logo.svg"
import hIcon1 from "../image/h_icon1.svg"
import hIcon2 from "../image/h_icon2.svg"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import ym from "react-yandex-metrika"
import { host } from "../classes"

export const Header = (props: any) => {
	const auth = useContext(GlobalContext)
	const [shopMenu, setShopMenu] = useState(false)
	const [shopNotification, setShopNotification] = useState(false)
	const [updateTest, setUpdateTest] = useState(false)

	const [natCount, setNatCount] = useState(0)
	const [chatCount, setChatCount] = useState(0)

	const myRefMenu = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (shopMenu && myRefMenu.current && !myRefMenu.current.contains(e.target)) {
				setShopMenu(false)
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [shopMenu])

	const myRefNotification = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const checkIfClickedOutside = (e: any) => {
			if (shopNotification && myRefNotification.current && !myRefNotification.current.contains(e.target)) {
				setShopNotification(false)
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [shopNotification])

	useEffect(() => {
		auth.user
			.LoadNotifications()
			.then(function (respond) {
				setUpdateTest((prev) => !prev)
				setNatCount(auth.user.notifications.length)
				setChatCount(auth.user.chatCount)
			})
			.catch(function (error) {
				alert("error")
			})

		const interval = setInterval(async () => {
			if (auth.user.isAuthenticated) {
				auth.user
					.LoadNotifications()
					.then(function (respond) {
						setUpdateTest((prev) => !prev)
						setNatCount(auth.user.notifications.length)
						setChatCount(auth.user.chatCount)
						console.log("test ajax")

						console.log(auth.user.notifications)
					})
					.catch(function (error) {
						alert("error")
					})
			}

			if (auth.user.isAuthenticated && auth.user.userType === "bloger")
				await axios
					.post(
						`${host}logintest/`,
						{},
						{
							headers: {
								"Content-Type": "application/json",
								Authorization: "Token " + auth.user.userToken,
							},
						},
					)
					.then((res) => {
						if (res.data.error === true) {
							auth.user.logout()
							window.location.href = "/"
						}
					})
					.catch((error) => {
						console.log("query error")
					})
		}, 60_000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return (
		<header>
			{auth.user.isAuthenticated ? (
				auth.user.userType === "bloger" ? (
					<div className="content">
						<div className="row">
							<div className="col-md-2">
								<Link to="/" className="grid">
									<img className="header__logo" src={logo} alt="" />
								</Link>
							</div>
							<div className="col-md-8">
								<div className="grid center">
									<ul>
										<li>
											<Link to="/account/">Мои задания</Link>
										</li>
										<li>
											<Link to="/application/">Мои заявки</Link>
										</li>
										<li>
											<Link to="/integrations-list/">Лента заданий</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-2">
								<div className="grid right">
									<div className="user-info">
										<div ref={myRefNotification} className="user-info__icon">
											<div
												className="grid"
												onClick={() => {
													setShopNotification(!shopNotification)
												}}
												style={{
													cursor: "pointer",
													position: "relative",
												}}
											>
												<div className="user-info__icon-count">{natCount}</div>
												<img src={hIcon2} alt="" />
											</div>
											{shopNotification ? (
												<div className="notifications__list">
													<ul>
														{auth.user.notifications ? (
															auth.user.notifications.length ? (
																auth.user.notifications.map((item, index) => {
																	return (
																		<li key={index}>
																			<div
																				className="notifications__list-date"
																				dangerouslySetInnerHTML={{
																					__html: item.date,
																				}}
																			></div>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: item.title,
																				}}
																			></span>
																			<br />
																			{item.action === "chat" ? (
																				<Link
																					onClick={() => {
																						setShopNotification(false)
																					}}
																					to={"/chat/"}
																				>
																					Перейти в чат
																				</Link>
																			) : (
																				""
																			)}
																		</li>
																	)
																})
															) : (
																<li>Список пуст</li>
															)
														) : (
															""
														)}
													</ul>
													<button
														onClick={() => {
															auth.user
																.DelNotifications()
																.then(function (response) {})
																.catch(function (error) {})
															setUpdateTest(!updateTest)
															setShopNotification(false)
														}}
													>
														Очистить
													</button>
												</div>
											) : (
												""
											)}
										</div>
										<div className="user-info__icon">
											<div
												className="grid"
												style={{
													cursor: "pointer",
													position: "relative",
												}}
											>
												{chatCount !== 0 ? (
													<div className="user-info__icon-count">{chatCount}</div>
												) : (
													""
												)}
												<Link to="/chat/">
													<img src={hIcon1} alt="" />
												</Link>
											</div>
										</div>
										<div
											ref={myRefMenu}
											className="user-info__img"
											style={{
												backgroundImage: "url('" + auth.user.image + "')",
											}}
											onClick={() => {
												setShopMenu(!shopMenu)
											}}
										>
											{shopMenu ? (
												<div>
													<ul>
														<li>
															<Link to={`/catalog/${auth.user.pk}/`}>Профиль</Link>
														</li>
														<li>
															<Link to="/blogger-balance/"> Баланс</Link>
														</li>
														<li>
															<Link to="/setting/">Настройки</Link>
														</li>
														<li className="user-info__img-exit">
															<a
																href="/"
																onClick={() => {
																	auth.user.logout()
																	return false
																}}
															>
																Выход
															</a>
														</li>
													</ul>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="content">
						<div className="row">
							<div className="col-md-2">
								<Link to="/" className="grid">
									<img className="header__logo" src={logo} alt="" />
								</Link>
							</div>
							<div className="col-md-8">
								<div className="grid center">
									<ul>
										<li>
											<Link to="/account/">Создать проект</Link>
										</li>
										<li>
											<Link to="/story/">Мои проекты</Link>
										</li>
										<li>
											<Link to="/catalog/">Каталог блогеров</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-2">
								<div className="grid right">
									<div className="user-info">
										<div ref={myRefNotification} className="user-info__icon">
											<div
												className="grid"
												onClick={() => {
													setShopNotification(!shopNotification)
												}}
												style={{
													cursor: "pointer",
													position: "relative",
												}}
											>
												{natCount ? <div className="user-info__icon-count">{natCount}</div> : ""}
												<img src={hIcon2} alt="" />
											</div>
											{shopNotification ? (
												<div className="notifications__list">
													<ul>
														{auth.user.notifications ? (
															auth.user.notifications.length ? (
																auth.user.notifications.map((item, index) => {
																	return (
																		<li key={index}>
																			<div
																				className="notifications__list-date"
																				dangerouslySetInnerHTML={{
																					__html: item.date,
																				}}
																			></div>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: item.title,
																				}}
																			></span>
																			<br />
																			{item.action === "chat" ? (
																				<Link
																					onClick={() => {
																						setShopNotification(false)
																					}}
																					to={"/chat/"}
																				>
																					Перейти в чат
																				</Link>
																			) : (
																				""
																			)}
																		</li>
																	)
																})
															) : (
																<li>Список пуст</li>
															)
														) : (
															""
														)}
													</ul>
													<button
														onClick={() => {
															auth.user
																.DelNotifications()
																.then(function (response) {})
																.catch(function (error) {})
															setUpdateTest(!updateTest)
															setShopNotification(false)
														}}
													>
														Очистить
													</button>
												</div>
											) : (
												""
											)}
										</div>
										<div className="user-info__icon">
											<div
												className="grid"
												style={{
													cursor: "pointer",
													position: "relative",
												}}
											>
												{chatCount !== 0 ? (
													<div className="user-info__icon-count">{chatCount}</div>
												) : (
													""
												)}
												<Link to="/chat/">
													<img src={hIcon1} alt="" />
												</Link>
											</div>
										</div>
										<div
											ref={myRefMenu}
											className="user-info__img"
											style={{
												backgroundImage: "url('" + auth.user.image + "')",
											}}
											onClick={() => {
												setShopMenu(!shopMenu)
											}}
										>
											{shopMenu ? (
												<div>
													<ul>
														<li>
															<Link to={`/seller/${auth.user.pk}/`}>Профиль</Link>
														</li>
														<li>
															<Link to="/setting/">Настройки</Link>
														</li>
														<li>
															<Link to="/pay-story/">Баланс</Link>
														</li>
														<li className="user-info__img-exit">
															<a
																href="/"
																onClick={() => {
																	auth.user.logout()
																	return false
																}}
															>
																Выход
															</a>
														</li>
													</ul>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			) : (
				<div className="content">
					<div className="row">
						<div className="col-md-4">
							<a href="/">
								<img className="header__logo" src={logo} alt="" />
							</a>
						</div>
						<div className="col-md-4">
							<div className="grid center">
								<ul>
									<li>
										<Link to="/">Продавцу</Link>
									</li>
									<li>
										<Link to="/bloger/">Блогеру</Link>
									</li>
									<li>
										<Link to="/about/">О нас</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="grid right">
								<Link
									to="/login/"
									className="header__login"
									onClick={() => {
										ym("reachGoal", "enter")
									}}
								>
									Войти
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
		</header>
	)
}
