import { axiosBase } from "../constants/axiosBase"

export type ChangeIntegrationAmountType = {
	id: number
	amount: number
}

export const changeIntegrationAmount = async (data:ChangeIntegrationAmountType) => {
	const response = await axiosBase.patch("/update-transfer-amount/", data)
	return response.status
}
