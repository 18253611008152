import React, { useContext } from "react"
import logo from "../image/logo.svg"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom"
import { DashbordPopup } from "./DashbordPopup"
import ym from "react-yandex-metrika"
import footertg from "../image/footertg.png"

import { YMInitializer } from "react-yandex-metrika"

export const Footer = (props: any) => {
	const auth = useContext(GlobalContext)

	return (
		<>
			<footer>
				<div className="content">
					<div className="row">
						<div className="col-md-4">
							<a href="/">
								<img className="header__logo" src={logo} alt="logo" />
							</a>
						</div>
						<div className="col-md-4">
							<div className="grid ">
								<ul>
									{auth.user.isAuthenticated ? (
										<>
											<li>
												<Link to="/data-base/">База знаний</Link>
											</li>
											<li>
												<Link to="/video/">Видеоуроки</Link>
											</li>
											<li>
												<Link to="/dashbord/">Онлайн-курс</Link>
											</li>
										</>
									) : (
										<>
											<li>
												<Link to="/about/">О нас</Link>
											</li>
											<li>
												<Link to="/contact/">Контакты</Link>
											</li>
											<li>
												<Link to="/questions/">Вопросы</Link>
											</li>
										</>
									)}
								</ul>
							</div>
						</div>
						<div className="col-md-4">
							<div className="grid right">
								<Link
									to={`${
										auth.user.userType === "bloger"
											? "https://t.me/blogerito"
											: "https://t.me/blogerito_ru"
									}`}
									className="fb-footer"
									target="_blank"
									onClick={() => {
										ym("reachGoal", "telegram")
									}}
								>
									<img src={footertg} alt="telegram" />
									Подпишитесь на
									<br />
									наш Телеграм канал
								</Link>
							</div>
							<div className="grid right" style={{ display: "none" }}>
								<div className="">
									<Link to={"https://www.youtube.com/channel/UCaCCe84W-gvA3bzDoqcsXHw"} target="_blank">
										<svg
											version="1.1"
											viewBox="0 0 49.1 50"
											style={{ width: "30px", height: "30px", marginRight: "10px" }}
										>
											<path
												fill="#3A0CA3"
												d="M39.5,9.4c1.2,0.1,2.4,0.2,3.5,0.4c2.8,0.5,4.6,2.4,5.2,5.4c0.7,4.1,0.9,8.2,0.7,12.3 c-0.1,2.9-0.4,5.8-0.7,8.7c-0.2,2.1-1.1,3.9-2.9,5.1c-1.1,0.7-2.4,0.9-3.7,1.1c-5,0.5-10.1,0.7-15.2,0.6c-5.1,0-10.2-0.1-15.3-0.3 c-1.6-0.1-3.3-0.3-4.9-0.6C3.5,41.7,1.6,39.8,1,37c-0.6-2.9-0.7-5.8-0.8-8.7c-0.1-3.8,0-7.7,0.5-11.5C0.8,16,1,15.1,1.2,14.3 c0.7-2.6,2.7-4.3,5.5-4.6C11.5,9.1,16.2,9,21,8.9c1.1,0,2.3,0,3.4,0C24.4,8.9,34.5,9.1,39.5,9.4z M19.7,18.7c0,4.9,0,9.7,0,14.6 c4.2-2.4,8.4-4.8,12.7-7.3C28.1,23.5,24,21.1,19.7,18.7z"
											/>
										</svg>
									</Link>
									<Link to={"https://t.me/blogerito_ru"} target="_blank">
										<svg
											version="1.1"
											x="0px"
											y="0px"
											viewBox="0 0 49.1 50"
											style={{ width: "30px", height: "30px" }}
										>
											<g id="Слой_1">
												<g>
													<path
														fill="#3A0CA3"
														d="M3.5,23.5c0.3-0.7,1-1.1,1.7-1.3c2.7-1.1,5.5-2.1,8.2-3.2c9.4-3.6,18.7-7.2,28.1-10.8C41.7,8.1,42,8.1,42.2,8 c1.3-0.2,2.1,0.6,2.1,1.9c0,0.9-0.2,1.7-0.4,2.6C42,21.6,40,30.6,38.1,39.7c-0.1,0.5-0.3,0.9-0.5,1.3c-0.4,0.8-1.1,1.1-2,0.9 c-0.4-0.1-0.9-0.3-1.2-0.6c-2.9-2.1-5.8-4.3-8.7-6.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-1.5,1.4-3,2.8-4.5,4.3 c-0.4,0.4-0.9,0.7-1.6,0.7c0-0.6,0.1-1.1,0.1-1.7c0.2-2.5,0.4-5,0.5-7.5c0-0.2,0.1-0.4,0.3-0.5c5.6-5.1,11.3-10.2,17-15.2l0.1-0.1 c0.1-0.2,0.2-0.4,0.3-0.6c-0.2,0-0.5-0.2-0.7-0.1c-0.3,0-0.5,0.2-0.8,0.4c-7,4.4-14.1,8.8-21.1,13.2c-0.2,0.1-0.4,0.1-0.6,0.1 c-3-0.9-6-1.8-9-2.8c-0.6-0.2-1.2-0.5-1.5-1C3.5,23.8,3.5,23.6,3.5,23.5z"
													/>
												</g>
											</g>
										</svg>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="underline">
						<div className="row">
							<div className="col-md-4">
								<p>
									ИП Матвеев М.М.
									<br />
									ИНН 772875163601
									<br />
									Москва, Шеногина 4 <br />
									Сделано в IT компании{" "}
									<a
										style={{ color: "#3A0CA3" }}
										target="_blank"
										href={"https://gemsteam.ru/"}
										rel="noreferrer"
									>
										ДЖЕМСТИМ
									</a>
								</p>
							</div>
							<div className="col-md-4">
								<div
									style={{
										paddingLeft: 10,
										lineHeight: "23px",
									}}
								>
									<Link
										to={"/privacy/"}
										style={{ fontSize: "14px", marginBottom: -10 }}
										className="header__login"
									>
										Политика Конфиденциальности
									</Link>
									<br />
									<Link to={"/user-agreement/"} style={{ fontSize: "14px" }} className="header__login">
										Пользовательское соглашение
									</Link>
									<br />
									<Link to={"/rules/"} style={{ fontSize: "14px" }} className="header__login">
										Внутренние правила Платформы
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>

			<YMInitializer accounts={[95405837]} />

			{auth.user.isAuthenticated && auth.user.userType !== "bloger" ? <DashbordPopup /> : ""}
		</>
	)
}
